import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslationRequest } from '../../../interfaces';
import { HttpService } from '../../../services/http-service';
import { TranslationService } from '../../../services/translation-service';
import { RequestInfoDialogComponent } from '../request-info-dialog/request-info-dialog.component';

@Component({
  selector: 'app-admin-edit-dialog',
  templateUrl: './admin-edit-dialog.component.html',
  styleUrls: ['./admin-edit-dialog.component.scss']
})
export class AdminEditDialogComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<RequestInfoDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public requestData: TranslationRequest,
    public translationService: TranslationService,
    public httpService: HttpService,
  ) { }

  ngOnInit(): void {
  }

}
