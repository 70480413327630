<div class="sign-up-wrapper" [ngClass]="{'horizontal': globalsService.isMobileView}">
  <section class="side-nav bg-primary" *ngIf="!globalsService.isMobileView">
    <h2 class="title side-title">
      {{'LABEL_SIGN_UP' | Translate: (translationService.$currentTranslation |
      async)}}
    </h2>

    <!--  CUSTOM STEPPER -->
    <div class="custom-stepper">
      <section *ngFor="let step of stepList">
        <ng-container *ngIf="!step.showCondition || checkCondition(step.showCondition)">
          <div class="custom-step" (click)="changeStep(step)">
            <mat-icon *ngIf="currentStep > step.Nr" class="step-icon bg-white color-primary" inline="true">check
            </mat-icon>
            <mat-icon *ngIf="currentStep === step.Nr" class="step-icon bg-white color-primary" inline="true">circle
            </mat-icon>
            <mat-icon *ngIf="currentStep < step.Nr && step?.formGroupToUnlock.valid"
              class="step-icon bg-white color-primary" inline="true">
              edit</mat-icon>
            <mat-icon *ngIf="currentStep < step.Nr && step?.formGroupToUnlock.invalid"
              [ngClass]="{'step-forbidden':step.isForbidden}" class="step-icon bg-white color-primary" inline="true">
              lock</mat-icon>
            <div class="custom-step-label">
              <span class="subtitle">
                {{step.title | Translate: (translationService.$currentTranslation | async)}}
              </span>
              <span class="custom-step-subtitle" *ngIf="step.subtitle">
                {{step.subtitle | Translate: (translationService.$currentTranslation | async)}}
              </span>
            </div>
          </div>
          <div *ngIf="step.Nr < stepList.length -1" class="step-connection-line"></div>
        </ng-container>
      </section>
    </div>
  </section>

  <section class="side-nav horizontal" *ngIf="globalsService.isMobileView">
    <div class="custom-stepper horizontal">
      <section *ngFor="let step of stepList">
        <ng-container *ngIf="!step.showCondition || checkCondition(step.showCondition)">
          <div class="custom-step horizontal" (click)="changeStep(step)">
            <div style="display:flex">
              <mat-icon *ngIf="currentStep > step.Nr" class="step-icon bg-primary" inline="true">check</mat-icon>
              <mat-icon *ngIf="currentStep === step.Nr" class="step-icon bg-primary" style="font-size: 1rem;"
                inline="true">circle</mat-icon>
              <mat-icon *ngIf="currentStep < step.Nr && step?.formGroupToUnlock.valid" class="step-icon bg-primary"
                inline="true">
                edit</mat-icon>
              <mat-icon *ngIf="currentStep < step.Nr && step?.formGroupToUnlock.invalid"
                [ngClass]="{'step-forbidden':step.isForbidden}" class="step-icon bg-primary" inline="true">lock
              </mat-icon>
              <div *ngIf="step.Nr < stepList.length -1" class="step-connection-line horizontal bg-primary"></div>
            </div>
            <span class="step-label">
              {{step.title | Translate: (translationService.$currentTranslation | async)}}
            </span>
          </div>
        </ng-container>
      </section>
    </div>
  </section>

  <!-- STEP 0: WHO AM I? -->
  <section aria-label="who am i" class="container" *ngIf="currentStep === 0"
    [ngClass]="{'horizontal': globalsService.isMobileView}">
    <span class="title">{{'LABEL_WELCOME' | Translate: (translationService.$currentTranslation | async)}}</span>
    <span class="subtitle">{{'PLS_SELECT_ROLE' | Translate: (translationService.$currentTranslation | async)}}</span>
    <span class="subtitle">{{'NEED_HELP_OR_WOULD_LIKE_TO_HELP' | Translate: (translationService.$currentTranslation |
      async)}}</span>
    <section class="role-select">
      <div class="toggle-btn bg-primary" (click)="selectRole('REQUESTOR')"
        [ngClass]="{'selected-role': roleFormGroup.value.role === 'REQUESTOR'}">
        <span class="subtitle" style="margin:auto">
          {{'NEED_HELP' | Translate: (translationService.$currentTranslation | async)}}
        </span>
        <!-- <span style="word-break: break-word;" *ngIf="!isMobileView">lorem ipsum set dolor</span> -->
      </div>
      <div class="toggle-btn bg-primary" (click)="selectRole('TRANSLATOR')"
        [ngClass]="{'selected-role': roleFormGroup.value.role === 'TRANSLATOR'}">
        <span class="subtitle" style="margin:auto">
          {{'CAN_HELP' | Translate: (translationService.$currentTranslation | async)}}
        </span>
        <!-- <span style="word-break: break-word;" *ngIf="!isMobileView">Lorem ipsum dolor sit amet, consetetur sadipscing elitr. </span> -->
      </div>
    </section>
    <!-- <button mat-raised-button color="primary" class="next-btn" [disabled]="roleFormGroup.invalid"
      (click)="currentStep=currentStep+1">
      {{'LABEL_CONTINUE' | Translate: (translationService.$currentTranslation |
      async)}}
    </button> -->
  </section>

  <!-- STEP 1: MY SKILLS -->
  <section aria-label="my skills" class="container" style="justify-content: start"
    *ngIf="currentStep === (stepList.length === 4 ? 1 : 99)">
    <form [formGroup]="skillsFormGroup">
      <div name="languages" class="form-control-div">
        <span class="mb-2">{{'I_KNOW_LANGUAGES' | Translate:
          (translationService.$currentTranslation | async)}}</span>
        <mat-button-toggle-group formControlName="language" aria-label="Languages i know" multiple="true">
          <mat-button-toggle value="UKRAINIAN" class="lg" style="margin-left:0!important">
            {{'UKRAINIAN' | Translate: (translationService.$currentTranslation |
            async)}}</mat-button-toggle>
          <mat-button-toggle value="RUSSIAN" class="lg">
            {{'RUSSIAN' | Translate: (translationService.$currentTranslation |
            async)}}</mat-button-toggle>
        </mat-button-toggle-group>
      </div>

      <div name="skills" class="form-control-div">
        <span class="mb-2">{{'I_CAN_HELP_WITH' | Translate:
          (translationService.$currentTranslation | async)}}</span>
        <mat-button-toggle-group formControlName="skills" aria-label="Type of translation" multiple="true">
          <mat-button-toggle value="TRANSLATE" class="lg" style="margin-left:0!important">
            {{'TRANSLATE' | Translate: (translationService.$currentTranslation |
            async)}}</mat-button-toggle>
          <mat-button-toggle value="INTERPRET" class="lg">
            {{'INTERPRET' | Translate: (translationService.$currentTranslation |
            async)}}</mat-button-toggle>
        </mat-button-toggle-group>
      </div>
    </form>
    <button mat-raised-button color="primary" class="next-btn" [disabled]="skillsFormGroup.invalid"
      (click)="currentStep=currentStep+1">
      {{'LABEL_CONTINUE' | Translate: (translationService.$currentTranslation |
      async)}}
    </button>
  </section>

  <!-- STEP 2: LOGIN DATA -->
  <section aria-label="login data" class="container" *ngIf="currentStep === (stepList.length === 4 ? 2 : 1)">
    <form [formGroup]="loginDataFormGroup">
      <div class="form-control-div">
        <mat-form-field appearance="outline">
          <mat-label>{{'LABEL_NAME' | Translate: (translationService.$currentTranslation |
            async)}}</mat-label>
          <mat-icon matPrefix class="prefix-icon">person</mat-icon>
          <input matInput formControlName="name" placeholder="..." required />
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>{{'LABEL_EMAIL' | Translate: (translationService.$currentTranslation |
            async)}}</mat-label>
          <mat-icon matPrefix class="prefix-icon">mail</mat-icon>
          <input matInput #email formControlName="email" type="email" placeholder="..." required />
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>{{'CONFIRM_EMAIL' | Translate: (translationService.$currentTranslation |
            async)}}</mat-label>
          <mat-icon matPrefix class="prefix-icon">mail</mat-icon>
          <input matInput formControlName="confirmEmail" type="email" placeholder="..." required
            pattern="{{ email.value }}" />
          <mat-hint style="color: red" *ngIf="loginDataFormGroup.controls?.confirmEmail?.errors?.pattern">
            {{'EMAIL_MISMATCH' | Translate: (translationService.$currentTranslation | async) }}
          </mat-hint>
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>{{'PASSWORD' | Translate: (translationService.$currentTranslation |
            async) }}</mat-label>
          <mat-icon matPrefix class="prefix-icon">lock</mat-icon>
          <input #password formControlName="password" required matInput [type]="hidePassword ? 'password' : 'text'" />
          <button mat-icon-button matSuffix (click)="hidePassword = !hidePassword" [attr.aria-label]="'Hide password'"
            [attr.aria-pressed]="hidePassword">
            <mat-icon>{{hidePassword ? 'visibility_off' : 'visibility'}}</mat-icon>
          </button>
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>{{'CONFIRM_PASSWORD' | Translate:
            (translationService.$currentTranslation | async) }}</mat-label>
          <mat-icon matPrefix class="prefix-icon">lock</mat-icon>
          <input formControlName="passwordConfirmation" required matInput [type]="hidePassword ? 'password' : 'text'" 
           pattern="{{ password.value }}" />
          <button mat-icon-button matSuffix (click)="hidePassword = !hidePassword" [attr.aria-label]="'Hide password'"
            [attr.aria-pressed]="hidePassword">
            <mat-icon>{{hidePassword ? 'visibility_off' : 'visibility'}}</mat-icon>
          </button>
          <mat-hint style="color: red"
            *ngIf="loginDataFormGroup.controls?.passwordConfirmation?.errors?.pattern">
            {{'PASSWORD_MISMATCH' | Translate:
            (translationService.$currentTranslation | async) }}</mat-hint>
        </mat-form-field>
        <mat-checkbox formControlName="acceptedAGB"  >
          <label [innerHTML]="'ACCEPT_AGB' | Translate: (translationService.$currentTranslation | async) : '../../../assets/files/AGB_TranslatorsAgainstWar.pdf'"></label>
         </mat-checkbox>
      </div>
    </form>
    <button mat-raised-button color="primary" class="next-btn" [disabled]="loginDataFormGroup.invalid"
      (click)="currentStep=currentStep+1">
      {{'LABEL_CONTINUE' | Translate: (translationService.$currentTranslation |
      async)}}
    </button>
  </section>

  <!-- STEP 3: CONTACT DATA -->
  <section aria-label="contact data" class="container" *ngIf="currentStep === (stepList.length === 4 ? 3 : 2)">
    <label *ngIf="roleFormGroup.value.role === 'REQUESTOR'">{{'ONLY_TRANSLATOR_CAN_VIEW_CONTACT_DATA' | Translate:
      (translationService.$currentTranslation | async)}}</label>
    <label *ngIf="roleFormGroup.value.role === 'TRANSLATOR'">{{'ONLY_REQUESTOWNER_CAN_VIEW_CONTACT_DATA' | Translate:
      (translationService.$currentTranslation | async)}}</label>
    <form [formGroup]="contactDataFormGroup">
      <div class="form-control-div">
        <mat-form-field appearance="outline">
          <mat-label>{{'LABEL_PHONE' | Translate:
            (translationService.$currentTranslation | async)}}</mat-label>
          <mat-icon matPrefix class="prefix-icon">phone</mat-icon>
          <input matInput type="number" formControlName="phone" placeholder="..." />
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>{{'LABEL_INSTAGRAM' | Translate:
            (translationService.$currentTranslation | async)}}</mat-label>
          <mat-icon matPrefix class="prefix-icon">photo_camera</mat-icon>
          <input matInput formControlName="instagram" type="email" placeholder="..." />
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>{{'LABEL_TELEGRAM' | Translate:
            (translationService.$currentTranslation | async)}}</mat-label>
          <mat-icon matPrefix class="prefix-icon">send</mat-icon>
          <input matInput formControlName="telegram" type="email" placeholder="..." />
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>{{'ZIP_CODE' | Translate:
            (translationService.$currentTranslation | async)}}</mat-label>
          <mat-icon matPrefix class="prefix-icon">location_on</mat-icon>
          <input matInput formControlName="zipCode" type="number" placeholder="..."
            [required]="roleFormGroup.value.role === 'TRANSLATOR'" />
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>{{'CITY' | Translate:
            (translationService.$currentTranslation | async)}}</mat-label>
          <mat-icon matPrefix class="prefix-icon">location_city</mat-icon>
          <input matInput formControlName="city" type="text" placeholder="..."
            [required]="roleFormGroup.value.role === 'TRANSLATOR'" />
        </mat-form-field>
      </div>
    </form>

    <button mat-raised-button color="primary" type="submit" class="next-btn" (click)="signUp()"
      [disabled]="roleFormGroup.value.role === 'TRANSLATOR' && (!contactDataFormGroup.value.zipCode || !contactDataFormGroup.value.city)">
      {{'BTN_SIGN_UP' | Translate: (translationService.$currentTranslation |
      async) }}
    </button>
  </section>
</div>