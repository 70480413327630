import { HttpClient } from "@angular/common/http";
import { Injectable, OnInit } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { Translate } from "../pipes/translation-pipe";
import { HttpService } from "./http-service";

@Injectable({ providedIn: 'root' })
export class TranslationService {
  userLanguage = {
    path: 'german',
    friendlyName: 'GERMAN',
    shortName: 'DEU'
  };

  languagesList = [
    {
      path: 'german',
      friendlyName: 'GERMAN',
      shortName: 'DEU'
    },
    {
      path: 'ukrainian',
      friendlyName: 'UKRAINIAN',
      shortName: 'UKR'
    },
    {
      path: 'russian',
      friendlyName: 'RUSSIAN',
      shortName: 'RUS'
    },
    {
      path: 'english',
      friendlyName: 'ENGLISH',
      shortName: 'ENG'
    },
  ]

  translationsData: any;

  $currentTranslation = new BehaviorSubject<any>({});

  constructor(
    private http: HttpClient,
    private translationPipe: Translate,
  ) {
    this.getTranslationsData();
  }

  async changeLanguage(selectedLanguageFriendlyname) {
    this.userLanguage = this.languagesList.find(lang => lang.friendlyName === selectedLanguageFriendlyname);
    this.getTranslationsData();   
  }

  public async getTranslationsData() {
    console.log('%cLOGGER', 'background: brown',' this.userLanguage ', this.userLanguage );

    this.translationsData = await this.http.get(`../assets/translations/${this.userLanguage.path}.json`).toPromise();
    this.$currentTranslation.next(this.translationsData);
    
    // subscribe(translationsData => {
    //   console.log('%cLOGGER', 'background: yellow','getTranslationsData', this.translationsData);
    //   this.translationsData = translationsData;
    //   this.$currentTranslation.next(translationsData);
    // }, err => console.log('%cERROR', 'background: red',': TranslationService -> err', err));
  }

  public translateMessage(message: string): string {
    const translatedMessage = this.translationPipe.transform(message, this.$currentTranslation.getValue());
    return translatedMessage;
  }
}