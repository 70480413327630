import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { Router } from '@angular/router';
import { Language } from '../../interfaces';
import { GlobalsService } from '../../services/globals.service';
import { HttpService } from '../../services/http-service';
import { NavigationService } from '../../services/navigation.service';
import { TranslationService } from '../../services/translation-service';

@Component({
  selector: 'app-header-menu',
  templateUrl: './header-menu.component.html',
  styleUrls: ['./header-menu.component.scss']
})
export class HeaderMenuComponent implements OnInit {
  // @ViewChild('drawer', { static: true }) drawer: MatSidenav;
  @Output() drawerToggle = new EventEmitter();
  constructor(
    private router: Router,
    public httpService: HttpService,
    public translationService: TranslationService,
    public navService: NavigationService,
    public globalsService: GlobalsService,
  ) {}

  ngOnInit(): void {
  }

  async changeLanguage(language: Language) {
    this.translationService.changeLanguage(language);
    if (this.httpService.isAuthenticated()) {
      await this.httpService.post('changeUserLanguage', { language: language });
    }
  }

  logout() {
    localStorage.removeItem('sessionid');
    this.httpService.sessionid = '';
    this.router.navigate(['login']);
  }

}
