import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: 'Translate',
  pure: true
})

export class Translate implements PipeTransform {
  constructor(
  ) { }
  transform(value: string, translations?: any, addLink?: string): string {
    if(!value) return value;
    if(value.includes(' ')){
      let words = value.split(' ');
      let translatedWords = [];
      words.forEach(word => {
        translatedWords.push(translations[word] || word);
      });
      return translatedWords.join(' ');
    }

    let result = translations[value] || value;
    if(addLink){
      // find $word$ in string
     const match = (/\$\b\w+\b\$/g).exec(result)?.[0];
     if(match){
        // replace $word$ with a link + translation of the word
        result = result.replace(match, `<a href="${addLink}" target="_blank">${translations[match.replace(/\$/g, '')]}</a>`);
      }
    }
    return result;
  }

}