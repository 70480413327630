import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { Injectable } from '@angular/core';
@Injectable({ providedIn: "root" })
export class GlobalsService {
  isMobileView: boolean;

  constructor(private breakpointObserver: BreakpointObserver) {
    this.breakpointObserver.observe([
      "(max-width: 768px)"
    ]).subscribe((result: BreakpointState) => {
      if (result.matches) {
        this.isMobileView = true;
      } else {
        this.isMobileView = false;
      }
    });
  }
}