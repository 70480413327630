<mat-drawer-container class="nav-container" autosize hasBackdrop="true">
  <!-- PERSONAL SETTINGS -->
  <mat-drawer #drawer class="sidenav" mode="over" position="end">
    <app-profile (drawer)="drawer.close()" *ngIf="drawer.opened"></app-profile>
  </mat-drawer>

  <!-- APP CONTENT -->
  <mat-drawer-content style="overflow: hidden">
    <div style="height: 100%; background-color: white">
      <div class="app-wrapper">
        <!-- HEADER MENU  -->
        <app-header-menu (drawerToggle)="drawer.toggle()"></app-header-menu>

        <!-- MAIN APP CONTENT -->
        <div
          class="main-content"
          [ngClass]="{
            'content-only': !(navService.showHeaderNavigation | async) && !(navService.showFooterNavigation | async),
            'content-header': (navService.showHeaderNavigation | async) && !(navService.showFooterNavigation | async),
            'content-header-footer': (navService.showFooterNavigation | async) && (navService.showFooterNavigation | async)
          }">
          <router-outlet></router-outlet>
        </div>
        <footer class="footer bg-secondary" *ngIf="navService.showFooterNavigation | async">
          <a class="color-primary" href="https://translatorsagainstwar.de/datenschutzerklaerung/">
            {{ 'PRIVACY' | Translate : (translationService.$currentTranslation | async) }}
          </a>
          <a class="color-primary" href="https://translatorsagainstwar.de/impressum/">
            {{ 'IMPRINT' | Translate : (translationService.$currentTranslation | async) }}
          </a>
        </footer>
      </div>
    </div>
  </mat-drawer-content></mat-drawer-container
>
