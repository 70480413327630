import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { Component, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatStepper } from '@angular/material/stepper';
import { ActivatedRoute, Router } from '@angular/router';
import { combineLatest } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
import { TouchSequence } from 'selenium-webdriver';
import { API_RESPONSE, Language } from '../../interfaces';
import { GlobalsService } from '../../services/globals.service';
import { HttpService } from '../../services/http-service';
import { NavigationService } from '../../services/navigation.service';
import { ToastService } from '../../services/toast-service';
import { TranslationService } from './../../services/translation-service';

@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SignUpComponent implements OnInit, OnDestroy {
  hidePassword = true;

  roleFormGroup: UntypedFormGroup = new UntypedFormGroup({
    role: new UntypedFormControl([], Validators.required),
  });

  skillsFormGroup: UntypedFormGroup = new UntypedFormGroup({
    language: new UntypedFormControl([], [Validators.required]),
    skills: new UntypedFormControl([], [Validators.required]),
    // official_translate_russian: new FormControl(false, [Validators.required]),
    // official_translate_ukrainian: new FormControl(false, [Validators.required]),
    // official_interpret_russian: new FormControl(false, [Validators.required]),
    // official_interpret_ukrainian: new FormControl(false, [Validators.required]),
  });

  loginDataFormGroup: UntypedFormGroup = new UntypedFormGroup({
    name: new UntypedFormControl('', [Validators.required]),
    email: new UntypedFormControl('', [Validators.required, Validators.email]),
    confirmEmail: new UntypedFormControl('', [Validators.required, Validators.email]),
    password: new UntypedFormControl('', [Validators.required]),
    passwordConfirmation: new UntypedFormControl('', [Validators.required]),
    acceptedAGB: new UntypedFormControl(false, [Validators.requiredTrue],),
  });

  contactDataFormGroup: UntypedFormGroup = new UntypedFormGroup({
    phone: new UntypedFormControl(''),
    instagram: new UntypedFormControl(''),
    telegram: new UntypedFormControl(''),
    zipCode: new UntypedFormControl(''),
    city: new UntypedFormControl(''),
  });

  currentStep = 0;
  stepList = [
    {
      Nr: 0,
      title: 'LABEL_WHO_AM_I',
      // subtitle: 'NEED_HELP_OR_WOULD_LIKE_TO_HELP',
    },
    {
      Nr: 1,
      title: 'SKILLS',
      // subtitle: 'PROVIDE_SKILL_INFO',
      formGroupToUnlock: this.roleFormGroup,
      showCondition: "this.roleFormGroup.value.role === 'TRANSLATOR'"
    },
    {
      Nr: 2,
      title: 'LOGIN_DATA',
      // subtitle: 'PROVIDE_LOGIN_INFO',
      formGroupToUnlock: this.skillsFormGroup,
    },
    {
      Nr: 3,
      title: 'CONTACT_DATA',
      // subtitle: 'PROVIDE_CONTACT_INFO',
      // subtitle: 'OPTIONAL',
      formGroupToUnlock: this.loginDataFormGroup,
    }
  ];

  acceptedAGB: boolean;

  constructor(
    // private httpClient: HttpClient,
    private httpService: HttpService,
    public translationService: TranslationService,
    private toastService: ToastService,
    private router: Router,
    private route: ActivatedRoute,
    private navService: NavigationService,
    public globalsService: GlobalsService,
  ) {
    const registerAs = this.route.snapshot.paramMap.get('registerAs');
    if (registerAs) {
      this.selectRole(registerAs.toUpperCase());
      if(registerAs === 'requestor' && this.translationService.userLanguage.friendlyName === Language.GERMAN) {
        this.translationService.changeLanguage(Language.UKRAINIAN);
      }
    }   
  }

  async ngOnInit(): Promise<void> {
    setInterval(() => {
      console.log('%cLOGGER', 'background: blue',' => setInterval => this.loginDataFormGroup:', this.loginDataFormGroup);
    } , 3000)
    // change dependency formGroup for LOGIN_DATA depending on selected ROLE 
    this.roleFormGroup.get('role').valueChanges.subscribe(value => {
      if (value === 'TRANSLATOR') {
        this.stepList.find(step => step.title === 'LOGIN_DATA').formGroupToUnlock = this.skillsFormGroup;
      } else {
        this.stepList.find(step => step.title === 'LOGIN_DATA').formGroupToUnlock = this.roleFormGroup;
      }
    });
  }

  changeStep(step) {
    if (!step.formGroupToUnlock || step.formGroupToUnlock.valid) {
      this.currentStep = step.Nr;
    } else {
      step.isForbidden = true;
      setTimeout(() => step.isForbidden = false, 2000);
    }
  }

  checkCondition(condition) {
    const conditionEvalResult = eval(condition);
    return conditionEvalResult;
  }

  onStepChange(event) {
    this.currentStep = event.selectedIndex;
  }

  selectRole(role: string) {
    this.roleFormGroup.get('role').setValue(role);
    setTimeout(() => this.currentStep += (role === 'TRANSLATOR' ? 1 : 2), 300);
  }



  async signUp() {
    const skills = [];
    if (this.roleFormGroup.value.role === 'TRANSLATOR') {
      this.skillsFormGroup.value.language.forEach(language => {
        this.skillsFormGroup.value.skills.forEach(skill => {
          skills.push(`${language}_${skill}`);
          // if (this.skillsFormGroup.value[`official_${skill}_${language}`.toLowerCase()]) {
          //   skills.push(`${language}_${skill}_OFFICIAL`);
          // }
        });
      });
    }
    const data = {
      role: this.roleFormGroup.value.role, // hilfesuchender
      skills: skills,
      email: this.loginDataFormGroup.value.email,
      password: this.loginDataFormGroup.value.password,
      name: this.loginDataFormGroup.value.name,
      telegram: this.contactDataFormGroup.value.telegram,
      phone: this.contactDataFormGroup.value.phone,
      instagram: this.contactDataFormGroup.value.instagram,
      zipCode: this.contactDataFormGroup.value.zipCode,
      city: this.contactDataFormGroup.value.city,
    }
    console.log('%cLOGGER', 'background: green', 'signUpData', data);
    const registerResp = (await this.httpService.post('register', data)) as API_RESPONSE;
    if (registerResp.success && registerResp.message) {
      this.toastService.showSuccessSnack(registerResp.message);
      // navigate the user to the login page & provide data to execute auto-login directly after navigation
      this.router.navigate(['login'], { state: { email: data.email, password: data.password } });
    } else if (registerResp.message) {
      this.toastService.showErrorSnack(registerResp.message);
    }

  }


  logout() {
    this.router.navigate(['login']);
  }

  ngOnDestroy(): void {
    this.navService.showHeaderNavigation.next(true);
    this.navService.showFooterNavigation.next(false);
  }
}
