import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DeleteRequestComponent } from './modules/delete-request/delete-request.component';
import { ForgotPasswordComponent } from './modules/login/forgot-password/forgot-password.component';
import { LoginComponent } from './modules/login/login.component';
import { SignUpComponent } from './modules/sign-up/sign-up.component';
import { CreateTranslationRequestComponent } from './modules/translation-request-list/create-translation-request/create-translation-request.component';
import { TranslationRequestListComponent } from './modules/translation-request-list/translation-request-list.component';
import { AuthGuardService as AuthGuard } from './services/auth-guard.service';

const routes: Routes = [
  // { path: '**', redirectTo: 'create-order', pathMatch: 'full' },
  { path: 'sign-up/:registerAs', pathMatch: 'full',component: SignUpComponent },
  { path: 'sign-up', pathMatch: 'full', component: SignUpComponent },
  { path: 'login', component: LoginComponent },
  { path: 'forgot-password', component: ForgotPasswordComponent },
  { path: 'translation-requests', component: TranslationRequestListComponent, canActivate: [AuthGuard] },
  { path: 'create-direct-request', component: CreateTranslationRequestComponent},
  { path: 'delete-request', component: DeleteRequestComponent},
  { path: '**', redirectTo: 'login', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash: true})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
