import { Component, Inject, Input, OnInit, Optional } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ConfirmationDialogData } from '../../interfaces';
import { TranslationService } from '../../services/translation-service';


@Component({
  selector: 'app-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss']
})
export class ConfirmationDialogComponent implements OnInit {
  title: string;
  message: string;
  btnOkText: string;
  btnCancelText: string;

  constructor(public dialogRef: MatDialogRef<ConfirmationDialogComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: ConfirmationDialogData,
    public translationService: TranslationService,) {
    this.title = data?.title || 'CONFIRMATION';
    this.message = data?.message || 'CONFIRMATION_MESSAGE';
    this.btnOkText = data?.btnOkText || 'CONFIRM';
    this.btnCancelText = data?.btnCancelText || 'CANCEL';
  }

  ngOnInit() {
  }

  public decline() {
    this.dialogRef.close(false);
  }

  public accept() {
    this.dialogRef.close(true);
  }
}
