import { Component, Input, OnInit } from '@angular/core';
import { API_RESPONSE } from '../../../interfaces';
import { HttpService } from '../../../services/http-service';
import { ToastService } from '../../../services/toast-service';
import { TranslationService } from '../../../services/translation-service';

const VERIFICATION_CODE_LENGTH = 6;
@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
/**
 * Component for forgot password
 * Flow: 
 * 1. user enters email address
 * 2. server sends email with verification code 
 * 3. user enters verification code
 * 4. user enters new password and saves it
 */
export class ForgotPasswordComponent implements OnInit {
  @Input() email: string;
  verificationCode: string;
  emailConfirmationResp: any = {};
  checkVerificationCodeResp: any = {};
  changeUserPasswordResp: any = {};

  hidePassword: boolean = true;
  password: string;
  passwordConfirmation: string;
  constructor(
    public translationService: TranslationService,
    private httpService: HttpService,
    private toastService: ToastService
  ) { }

  ngOnInit(): void {
  }

  async createConfirmationEmail(email: string) {
    this.emailConfirmationResp = (await this.httpService.post('createUserVerificationCode', { email: email })) as API_RESPONSE;
  }

  async checkUserVerificationCode(email: string, verificationCode: string) {
    if (verificationCode.length === VERIFICATION_CODE_LENGTH) {
      this.checkVerificationCodeResp = (await this.httpService.post('checkUserVerificationCode', { email: email, verificationCode: verificationCode })) as API_RESPONSE;
    }
  }

  async changeUserPassword(email: string, verificationCode: string, password: string) {
    this.changeUserPasswordResp = (await this.httpService.post('changeUserPassword', { email: email, verificationCode: verificationCode, password: password })) as API_RESPONSE;
    if (this.changeUserPasswordResp.success) {
      this.toastService.showSuccessSnack(this.changeUserPasswordResp.message, 'SUCCESS');
    } else if (this.changeUserPasswordResp.message) {
      this.toastService.showErrorSnack(this.changeUserPasswordResp.message, 'ERROR');
    }
  }


}
