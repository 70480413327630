<div style="min-height: 80vh;">
  <h3 style="text-align: center;"> {{'RESTORE_PASSWORD' | Translate: (translationService.$currentTranslation | async) }}
  </h3>
  <section name="sent confirmation email" class="restore-password-section">
    <mat-form-field appearance="outline" class="field">
      <mat-label>{{'LABEL_EMAIL' | Translate: (translationService.$currentTranslation | async)}}</mat-label>
      <mat-icon matPrefix>mail</mat-icon>
      <input matInput type='email' [(ngModel)]="email" pattern=".+@.+">
      <mat-hint *ngIf="!emailConfirmationResp?.success && emailConfirmationResp?.message" style="color:red">
        {{emailConfirmationResp.message | Translate: (translationService.$currentTranslation | async)}}
      </mat-hint>
      <mat-hint *ngIf="emailConfirmationResp?.success && emailConfirmationResp?.message" class="color-primary">
        {{emailConfirmationResp.message | Translate: (translationService.$currentTranslation | async)}}
      </mat-hint>
    </mat-form-field>

    <button mat-raised-button color="primary" class="field" style="margin: 1rem 0;padding: 0.5rem 1rem;"
      (click)="createConfirmationEmail(email)">
      {{'SEND_CONFIRMATION_EMAIL' | Translate: (translationService.$currentTranslation | async)}}
    </button>
  </section>

  <section name="change password" *ngIf="emailConfirmationResp?.success" class="restore-password-section">
    <mat-form-field appearance="outline" class="field">
      <mat-label>{{'CONFIRMATION_CODE' | Translate: (translationService.$currentTranslation | async)}}</mat-label>
      <mat-icon matPrefix>key</mat-icon>
      <input matInput type='text' [(ngModel)]="verificationCode" [maxLength]="6" [minlength]="6"
        (keyup)="checkUserVerificationCode(email, verificationCode)">
      <mat-hint *ngIf="!checkVerificationCodeResp?.success && checkVerificationCodeResp?.message" style="color:red">
        {{checkVerificationCodeResp.message | Translate: (translationService.$currentTranslation | async)}}
      </mat-hint>
      <mat-hint *ngIf="checkVerificationCodeResp?.success && checkVerificationCodeResp?.message" class="color-primary">
        {{checkVerificationCodeResp.message | Translate: (translationService.$currentTranslation | async)}}
      </mat-hint>
    </mat-form-field>
  </section>

  <section aria-label="change password" *ngIf="checkVerificationCodeResp?.success" class="restore-password-section">
    <mat-form-field appearance="outline" class="field">
      <mat-label>{{'PASSWORD' | Translate: (translationService.$currentTranslation | async) }}</mat-label>
      <mat-icon matPrefix>lock</mat-icon>
      <input [(ngModel)]="password" required matInput [type]="hidePassword ? 'password' : 'text'">
      <button mat-icon-button matSuffix (click)="hidePassword = !hidePassword" [attr.aria-label]="'Hide password'"
        [attr.aria-pressed]="hidePassword">
        <mat-icon>{{hidePassword ? 'visibility_off' : 'visibility'}}</mat-icon>
      </button>
    </mat-form-field>
    <mat-form-field appearance="outline" class="field">
      <mat-label>{{'CONFIRM_PASSWORD' | Translate: (translationService.$currentTranslation | async) }}</mat-label>
      <mat-icon matPrefix>lock</mat-icon>
      <input [(ngModel)]="passwordConfirmation" required matInput [type]="hidePassword ? 'password' : 'text'">
      <button mat-icon-button matSuffix (click)="hidePassword = !hidePassword" [attr.aria-label]="'Hide password'"
        [attr.aria-pressed]="hidePassword">
        <mat-icon>{{hidePassword ? 'visibility_off' : 'visibility'}}</mat-icon>
      </button>
      <mat-hint style="color:red;" *ngIf="password && passwordConfirmation && (password !== passwordConfirmation)">
        {{'PASSWORD_MISMATCH' | Translate: (translationService.$currentTranslation | async) }}</mat-hint>
    </mat-form-field>
    <button mat-raised-button color="primary"
      [disabled]="!password || !passwordConfirmation || (password !== passwordConfirmation)"
      (click)="changeUserPassword(email, verificationCode, password)">
      {{'CHANGE_PASSWORD' | Translate: (translationService.$currentTranslation | async)}}
    </button>
  </section>
</div>