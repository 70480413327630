import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { HttpService } from './http-service';
@Injectable({providedIn: "root"})
export class AuthGuardService implements CanActivate {
  constructor(private httpService: HttpService, public router: Router) {}
  canActivate(): boolean {
    console.log('%cLOGGER', 'background: orange',': AuthGuardService -> isAuthenticated()', this.httpService.isAuthenticated());
    if (!this.httpService.isAuthenticated()) {
      this.router.navigate(['login']);
      return false;
    }
    return true;
  }
}