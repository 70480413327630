<div class="request-info-wrapper">
  <div class="content">
    <!--  TAGS  -->
    <section class="tags">
      <label class="tag" [ngClass]="requestData.requestType === 'INTERPRET' ? 'orange-tag' : 'green-tag'">
        {{ requestData.requestType | Translate : (translationService.$currentTranslation | async) }}
      </label>
      <label class="tag" [ngClass]="requestData.languageFrom === 'RUSSIAN' ? 'blue-tag' : 'yellow-tag'">
        {{ requestData.languageFrom | Translate : (translationService.$currentTranslation | async) }}
        -
        {{ requestData.languageTo | Translate : (translationService.$currentTranslation | async) }}
      </label>
      <label class="tag purple-tag text-sm" *ngIf="requestData.medicalTranslation">
        {{ 'MEDICAL_TRANSLATION' | Translate : (translationService.$currentTranslation | async) }}
      </label>
      <label class="tag light-blue-tag text-sm" *ngIf="requestData.handwritten">
        {{ 'HANDWRITTEN' | Translate : (translationService.$currentTranslation | async) }}
      </label>
      <label class="tag purple-tag text-sm" *ngIf="requestData.phoneInterpretation">
        {{ 'PHONE_INTERPRETATION' | Translate : (translationService.$currentTranslation | async) }}
      </label>
    </section>

    <!-- TRANSLATION REQUEST -->
    <section class="text-data" *ngIf="requestData.requestType === 'TRANSLATE'">
      <label>
        <span style="font-weight: 600">
          {{ 'TEXT_TYPE' | Translate : (translationService.$currentTranslation | async) }}:
        </span>
        {{ requestData.textType }}
      </label>
      <label>
        <span style="font-weight: 600">
          {{ 'AMOUNT_OF_PAGES' | Translate : (translationService.$currentTranslation | async) }}:
        </span>
        {{ requestData.textAmountOfPages }}
      </label>
    </section>
    <!-- INTERPRETATION REQUEST -->
    <section class="text-data" *ngIf="requestData.requestType === 'INTERPRET'">
      <label>
        <span style="font-weight: 600">
          {{ 'APPOINTMENT_THEME' | Translate : (translationService.$currentTranslation | async) }}:
        </span>
        {{ requestData.appointmentTheme }}
      </label>
      <label>
        <span style="font-weight: 600">
          {{ 'APPOINTMENT_DATE_TIME' | Translate : (translationService.$currentTranslation | async) }}:
        </span>
        {{ requestData.appointmentDateTime | date : 'dd.MM.yyyy HH:mm' }}
      </label>
      <label>
        <span style="font-weight: 600">
          {{ 'APPOINTMENT_DURATION' | Translate : (translationService.$currentTranslation | async) }}:
        </span>
        {{ requestData.appointmentDuration + ('HOURS' | Translate : (translationService.$currentTranslation | async)) }}
      </label>
      <label>
        <span style="font-weight: 600">
          {{ 'APPOINTMENT_ADDRESS' | Translate : (translationService.$currentTranslation | async) }}:
        </span>
        {{ requestData.appointmentAddress }}
      </label>
    </section>
    <label *ngIf="requestData.comment">
      <span style="font-weight: 600">
        {{ 'ADMIN_COMMENT' | Translate : (translationService.$currentTranslation | async) }}:
      </span>
      <em>{{ requestData.comment }}</em>
    </label>

    <!----------- CONTENT DESCRIPTION ----------->
    <!-- <mat-divider style="margin: 1rem 0"></mat-divider> -->
    <p
      style="
        margin-top: 1rem;
        word-break: break-all;
        max-height: 20rem;
        overflow: auto;
        border: 1px solid rgba(0, 0, 0, 0.12);
        padding: 1rem;
      ">
      {{ requestData.description }}
    </p>
    <!-- <mat-divider style="margin: 1rem 0"></mat-divider> -->
  </div>

  <div class="external-data">
    <section class="request-data-wrapper">
      <div class="icon-info">
        <div class="icon-info-item">
          <mat-icon>tag</mat-icon> <span>{{ requestData.Nr }}</span>
        </div>
        <div class="icon-info-item">
          <mat-icon>calendar_month</mat-icon>
          <span>{{ requestData.createdAt | date : 'dd.MM.yyyy' }}</span>
        </div>
      </div>

      <mat-divider style="margin: 1rem 0"></mat-divider>
      <!-- Requestor Contact -->
      <div class="icon-info">
        <label class="bold">{{ 'REQUESTOR' | Translate : (translationService.$currentTranslation | async) }}</label>
        <label *ngIf="!showContacts" class="hide-contact-tipp"
          >{{ 'ONLY_TRANSLATOR_CAN_SEE_CONTACT_DATA' | Translate : (translationService.$currentTranslation | async) }}
        </label>
        <div class="icon-info-item">
          <mat-icon>person</mat-icon>
          <span [ngClass]="{ blur: !showContacts }">{{ requestData.requestor.name || 'example_contact_data' }}</span>
        </div>
        <div class="icon-info-item">
          <mat-icon>alternate_email</mat-icon>
          <span [ngClass]="{ blur: !showContacts }">{{ requestData.requestor.email || 'example_contact_data' }}</span>
        </div>
        <div *ngIf="!showContacts || requestData?.requestor?.phone" class="icon-info-item">
          <mat-icon>call</mat-icon>
          <span [ngClass]="{ blur: !showContacts }">{{ requestData.requestor.phone || 'example_contact_data' }}</span>
        </div>
        <div *ngIf="!showContacts || requestData?.requestor?.telegram" class="icon-info-item">
          <mat-icon>send</mat-icon>
          <span [ngClass]="{ blur: !showContacts }">{{
            requestData.requestor.telegram || 'example_contact_data'
          }}</span>
        </div>
        <div *ngIf="!showContacts || requestData?.requestor?.instagram" class="icon-info-item">
          <mat-icon>photo_camera</mat-icon>
          <span [ngClass]="{ blur: !showContacts }">{{
            requestData.requestor.instagram || 'example_contact_data'
          }}</span>
        </div>
      </div>
      <mat-divider *ngIf="requestData.translator" style="margin: 1rem 0"></mat-divider>
      <!-- Translator Contact -->
      <div class="icon-info" *ngIf="requestData.translator">
        <label class="bold">{{ 'TRANSLATOR' | Translate : (translationService.$currentTranslation | async) }}</label>
        <div class="icon-info-item">
          <mat-icon>person</mat-icon>
          <span>{{ requestData.translator.name || 'example_contact_data' }}</span>
        </div>
        <div class="icon-info-item">
          <mat-icon>alternate_email</mat-icon>
          <span>{{ requestData.translator.email || 'example_contact_data' }}</span>
        </div>
        <div *ngIf="!showContacts || requestData?.translator?.phone" class="icon-info-item">
          <mat-icon>call</mat-icon>
          <span>{{ requestData.translator.phone || 'example_contact_data' }}</span>
        </div>
        <div *ngIf="!showContacts || requestData?.translator?.telegram" class="icon-info-item">
          <mat-icon>send</mat-icon>
          <span>{{ requestData.translator.telegram || 'example_contact_data' }}</span>
        </div>
        <div *ngIf="!showContacts || requestData?.translator?.instagram" class="icon-info-item">
          <mat-icon>photo_camera</mat-icon>
          <span>{{ requestData.translator.instagram || 'example_contact_data' }}</span>
        </div>
      </div>
    </section>
  </div>

  <section align="end" class="footer-action-bar">
    <button mat-button color="warn" (click)="closeDialog()">
      {{ 'CLOSE' | Translate : (translationService.$currentTranslation | async) }}
    </button>

    <button mat-button color="warn"  *ngIf="showContacts && requestData.status ===  'IN_PROGRESS'" (click)="cancelRequest(requestData.Nr)">
      {{ 'ABANDON' | Translate : (translationService.$currentTranslation | async) }}
    </button>

    <button mat-button color="primary" *ngIf="!showContacts" (click)="assignRequest(requestData.Nr)">
      {{ 'ACCEPT' | Translate : (translationService.$currentTranslation | async) }}
    </button>
  </section>
</div>
