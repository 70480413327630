<section class="profil-wrapper">
  <h3 class="bg-secondary subtitle title">{{ "USER_PROFILE" | Translate: (translationService.$currentTranslation |
    async) }}</h3>
  <section class="profile" *ngIf="userData">
    <mat-accordion class="example-headers-align" multi *ngIf="userData">
      <!-- PERSONAL DATA PANEL -->
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>
            <mat-icon class="icon-margin">account_circle</mat-icon>
            {{ "PERSONAL_DATA" | Translate: (translationService.$currentTranslation | async) }}
          </mat-panel-title>
        </mat-expansion-panel-header>
        <section class="fields-list">
          <mat-form-field appearance="outline">
            <mat-label>{{'LABEL_NAME' | Translate: (translationService.$currentTranslation |
              async)}}</mat-label>
            <mat-icon matPrefix class="prefix-icon">person</mat-icon>
            <input matInput [(ngModel)]="userData.name" placeholder="..." required />
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>{{'LABEL_EMAIL' | Translate: (translationService.$currentTranslation |
              async)}}</mat-label>
            <mat-icon matPrefix class="prefix-icon">mail</mat-icon>
            <input matInput [(ngModel)]="userData.email" type="email" placeholder="..." required />
          </mat-form-field>
        </section>
      </mat-expansion-panel>

      <!-- CONTACT DATA PANEL -->
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>
            <mat-icon class="icon-margin">contact_phone</mat-icon>
            {{'CONTACT_DATA' | Translate: (translationService.$currentTranslation | async)}}
          </mat-panel-title>
        </mat-expansion-panel-header>
        <section class="fields-list">
          <mat-form-field appearance="outline">
            <mat-label>{{'LABEL_PHONE' | Translate:
              (translationService.$currentTranslation | async)}}</mat-label>
            <mat-icon matPrefix class="prefix-icon">phone</mat-icon>
            <input matInput type="number" [(ngModel)]="userData.phone" placeholder="..." />
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>{{'LABEL_INSTAGRAM' | Translate:
              (translationService.$currentTranslation | async)}}</mat-label>
            <mat-icon matPrefix class="prefix-icon">photo_camera</mat-icon>
            <input matInput [(ngModel)]="userData.instagram" type="email" placeholder="..." />
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>{{'LABEL_TELEGRAM' | Translate:
              (translationService.$currentTranslation | async)}}</mat-label>
            <mat-icon matPrefix class="prefix-icon">send</mat-icon>
            <input matInput [(ngModel)]="userData.telegram" type="email" placeholder="..." />
          </mat-form-field>
          <mat-form-field appearance="outline" *ngIf="userData.role !== 'REQUESTOR'">
            <mat-label>{{'ZIP_CODE' | Translate:
              (translationService.$currentTranslation | async)}}</mat-label>
            <mat-icon matPrefix class="prefix-icon">location_on</mat-icon>
            <input matInput [(ngModel)]="userData.zipCode" type="number" placeholder="..." />
          </mat-form-field>
          <mat-form-field appearance="outline" *ngIf="userData.role !== 'REQUESTOR'">
            <mat-label>{{'CITY' | Translate:
              (translationService.$currentTranslation | async)}}</mat-label>
            <mat-icon matPrefix class="prefix-icon">location_city</mat-icon>
            <input matInput [(ngModel)]="userData.city" type="text" placeholder="..." />
          </mat-form-field>
        </section>
      </mat-expansion-panel>

      <!-- SKILL DATA PANEL -->
      <mat-expansion-panel *ngIf="userData.role !== 'REQUESTOR'">
        <mat-expansion-panel-header>
          <mat-panel-title>
            <mat-icon class="icon-margin">language</mat-icon>
            {{ 'SKILLS' | Translate: (translationService.$currentTranslation | async) }}
          </mat-panel-title>
        </mat-expansion-panel-header>
        <section class="fields-list">
          <div name="languages" class="block">
            <span class="mb-1">{{'I_KNOW_LANGUAGES' | Translate:
              (translationService.$currentTranslation | async)}}</span>
            <mat-button-toggle-group [(ngModel)]="languages" aria-label="Languages i know" multiple="true">
              <mat-button-toggle value="UKRAINIAN">
                {{'UKRAINIAN' | Translate: (translationService.$currentTranslation | async)}}
              </mat-button-toggle>
              <mat-button-toggle value="RUSSIAN">
                {{'RUSSIAN' | Translate: (translationService.$currentTranslation | async)}}
              </mat-button-toggle>
            </mat-button-toggle-group>
          </div>
          <div name="skills" class="block">
            <span class="mb-1">{{'I_CAN_HELP_WITH' | Translate:
              (translationService.$currentTranslation | async)}}</span>
            <mat-button-toggle-group [(ngModel)]="skills" aria-label="Type of translation" multiple="true">
              <mat-button-toggle value="TRANSLATE">
                {{'TRANSLATE' | Translate: (translationService.$currentTranslation | async)}}
              </mat-button-toggle>
              <mat-button-toggle value="INTERPRET">
                {{'INTERPRET' | Translate: (translationService.$currentTranslation | async)}}
              </mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </section>
      </mat-expansion-panel>

      <!-- USER FILTER -->
      <mat-expansion-panel *ngIf="userData.role !== 'REQUESTOR'">
        <mat-expansion-panel-header>
          <mat-panel-title>
            <mat-icon class="icon-margin">tune</mat-icon>
            {{ 'PERSONAL_FILTER' | Translate: (translationService.$currentTranslation | async) }}
          </mat-panel-title>
        </mat-expansion-panel-header>
        <section class="fields-list">
          <mat-slide-toggle [(ngModel)]="userData.personalFilter.hideMedicalTranslation" color="primary" style="margin-bottom: 1rem;">
            {{ "MEDICAL_TRANSLATION" | Translate: (translationService.$currentTranslation | async) }}
          </mat-slide-toggle>
        </section>
      </mat-expansion-panel>

      <!-- ADMIN PANEL -->
      <mat-expansion-panel *ngIf="userData.role === 'ADMIN'">
        <mat-expansion-panel-header>
          <mat-panel-title>
            <mat-icon class="icon-margin">admin_panel_settings</mat-icon>
            {{ 'ADMIN_PANEL' | Translate: (translationService.$currentTranslation | async) }}
          </mat-panel-title>
        </mat-expansion-panel-header>

        <!-- PIE CHART -->
        <section class="pie-chart-wrapper">
          <canvas baseChart [type]="'pie'" [data]="pieChartData" [options]="pieChartOptions" [legend]="true">
          </canvas>
        </section>
        <h4>
          {{'REQUEST_TOTAL' | Translate: (translationService.$currentTranslation | async)}} : {{allRequestsCount}}
        </h4>

        <div class="block">
          <mat-form-field>
            <mat-label>Enter a date range</mat-label>
            <mat-date-range-input [rangePicker]="picker">
              <input matStartDate placeholder="Start date" [(ngModel)]="userData.dateFrom">
              <input matEndDate placeholder="End date" [(ngModel)]="userData.dateTo">
            </mat-date-range-input>
            <!-- <mat-hint>MM/DD/YYYY – MM/DD/YYYY</mat-hint> -->
            <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-date-range-picker #picker></mat-date-range-picker>
          </mat-form-field>

          <button mat-raised-button color="primary" (click)="downloadRequestStatistic()">
            <mat-icon>file_download</mat-icon>
            {{ 'DOWNLOAD_REQUEST_STATISTICS' | Translate: (translationService.$currentTranslation | async) }}
          </button>
          <!-- <button mat-raised-button color="primary" (click)="downloadTranslatorsStatistic()">
            <mat-icon>file_download</mat-icon>
            {{ 'DOWNLOAD_TRANSLATORS_STATISTICS' | Translate: (translationService.$currentTranslation | async) }}
          </button>
          <button mat-raised-button color="primary" (click)="downloadRequestCountStatistic()">
            <mat-icon>file_download</mat-icon>
            {{ 'DOWNLOAD_REQUEST_COUNT_STATISTICS' | Translate: (translationService.$currentTranslation | async) }}
          </button> -->
        </div>
      </mat-expansion-panel>
    </mat-accordion>
    <div class="actions-btns">
      <button mat-raised-button color="primary" (click)="updateUserProfile()">
        {{'UPDATE_PROFILE' | Translate: (translationService.$currentTranslation | async)}}
      </button>
      <button mat-raised-button color="danger" (click)="closeDrawer()">
        {{'CANCEL' | Translate: (translationService.$currentTranslation | async)}}
      </button>
    </div>
  </section>
  <label color="danger" class="deleteAccBtn" (click)="deleteUserAccount()">
    {{'DELETE_PROFILE' | Translate: (translationService.$currentTranslation | async)}} ?
  </label>

</section>