<mat-tab-group mat-stretch-tabs (selectedTabChange)="onTabChange($event)" class="tab-group-wrapper">
  <mat-tab class="subtitle" *ngIf="httpService.role === 'TRANSLATOR' ||  httpService.role === 'ADMIN'" [label]="'ALL_REQUESTS' | Translate: (translationService.$currentTranslation | async)">
    <div *ngIf="!requestList.length">
      <p class="empty-list color-danger">{{'NO_REQUESTS_FOUND' | Translate: (translationService.$currentTranslation | async)}}</p>
    </div>
    <section class="cards-wrapper" [ngClass]="{'fit-cards': requestList.length > 3}">
      <app-translation-request *ngFor="let translationRequest of requestList; let i = index"
        [translationRequest]="translationRequest" viewType="MATCHING"
        (editRequest)="createRequest($event)" 
        (refreshRequestList)="getTranslationRequests($event)">
      </app-translation-request>      
    </section>
  </mat-tab>
  <mat-tab class="subtitle pb-4" *ngIf="httpService.role === 'TRANSLATOR' ||  httpService.role === 'REQUESTOR'" [label]="(httpService.role === 'TRANSLATOR'? 'ACCEPTED_REQUESTS': 'MY_REQUESTS') | Translate: (translationService.$currentTranslation | async)">
    <div *ngIf="!requestList.length">
      <p *ngIf="httpService.role === 'TRANSLATOR'" class="empty-list color-primary">{{'REQUESTLIST_EMPTY_ACCEPT_SOME' | Translate: (translationService.$currentTranslation | async)}}</p>
      <p *ngIf="httpService.role === 'REQUESTOR'" class="empty-list color-primary">{{'REQUESTLIST_EMPTY_CREATE_SOME' | Translate: (translationService.$currentTranslation | async)}}</p>
    </div>
    <section class="cards-wrapper">
      <!-- CREATE REQUEST CARD -->
      <div *ngIf="httpService.role === 'REQUESTOR'">
        <mat-card class="card" (click)="createRequest()">
          <!-- <button mat-icon-button style="width: 100%;height: 100%;"> -->
            <div class="create-request-wrapper">
            <mat-icon inline="true" style="font-size: 5rem; height: 3rem; line-height: 3rem;"  class="color-primary">add</mat-icon>
            <label>{{'CREATE_NEW_REQUEST' | Translate: (translationService.$currentTranslation | async)}}</label>
          </div>
        </mat-card>
      </div>
      <app-translation-request *ngFor="let translationRequest of requestList;"
        [translationRequest]="translationRequest" viewType="OWN" 
        (editRequest)="createRequest($event)"
        (refreshRequestList)="getTranslationRequests($event)">
      </app-translation-request>   
    </section>
  </mat-tab>
</mat-tab-group>