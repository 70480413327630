<div class="login-wrapper">
  <section class="taw-info bg-secondary">
    <a href="https://translatorsagainstwar.com/#/translation-requests">
      <img class="logo" src="../../../assets/images/translatorsagainstwar_logo.png">
    </a>
    <div class="description-text">
      <p>{{"TAW_INFO_DESCRRIPTION"| Translate: (translationService.$currentTranslation | async)}}</p>

      <p>{{"TAW_INFO_ACTION" | Translate: (translationService.$currentTranslation | async)}}</p>

      <p>{{"TAW_INFO_WARNING" | Translate: (translationService.$currentTranslation | async)}}</p>
    </div>
    
    <section class="footer" *ngIf="!globalsService.isMobileView">
      <a class="color-primary" href="https://translatorsagainstwar.de/datenschutzerklaerung/">
        {{"PRIVACY" | Translate: (translationService.$currentTranslation | async)}}
      </a>
      <a class="color-primary" href="https://translatorsagainstwar.de/impressum/">
        {{"IMPRINT" | Translate: (translationService.$currentTranslation | async)}}
      </a>
    </section>
  </section>

  <section class="login">
    <div class="menu-horizontal-end borderless-select">
      <mat-form-field appearance="outline" class="lang-select borderless-select">
        <!-- <mat-label>LANGUAGE</mat-label> -->
        <mat-select [ngModel]="translationService.userLanguage.friendlyName"
          (selectionChange)="translationService.changeLanguage($event.value)">
          <mat-option *ngFor="let language of translationService.languagesList" [value]="language.friendlyName">
            {{ language.friendlyName | Translate: (translationService.$currentTranslation | async) }}
          </mat-option>
        </mat-select>
        <mat-icon matPrefix inline="true" class="lang-icon">translate</mat-icon>
      </mat-form-field>
      <!-- <a [href]="'../../../assets/files/Tutorial_Requestor_'+translationService.userLanguage.shortName+'.pdf'">
        <mat-icon color="primary" inline="true" class="help-icon">help</mat-icon>
      </a> -->
    </div>

    <div style="display: flex; height: 70%;">
      <div class="login-card">
        <h2 class="subtitle color-primary">{{'LABEL_SIGN_IN_WITH_YOUR_EMAIL' | Translate:
          (translationService.$currentTranslation |
          async)}}</h2>
        <h3 class="w-100">
          <label style="display:block">{{'LABEL_DONT_HAVE_AN_ACCOUNT' | Translate:
            (translationService.$currentTranslation | async)}}
            {{'REGISTER_YOU' | Translate: (translationService.$currentTranslation | async)}}:</label>
          <label class="color-primary" style="cursor:pointer;" (click)="goToRegister('requestor')">
            {{'REQUESTOR' | Translate: (translationService.$currentTranslation | async)}}
          </label>
          <a [href]="'../../../assets/files/TUTORIAL_REQUESTOR_'+translationService.userLanguage.shortName+'.pdf'" target="_blank">
            <mat-icon color="primary" inline="true" style="font-size: 1.2rem;" class="help-icon">help</mat-icon>
          </a>
          /
          <label class="color-primary" style="cursor:pointer" (click)="goToRegister('translator')">
            {{'TRANSLATOR' | Translate: (translationService.$currentTranslation | async)}}
          </label>
          <a [href]="'../../../assets/files/TUTORIAL_TRANSLATOR_DEU.pdf'" target="_blank">
            <mat-icon color="primary" inline="true" style="font-size: 1.2rem;" class="help-icon">help</mat-icon>
          </a>
          <label class="color-primary" style="cursor:pointer" (click)="goToDirectCreate()">
            {{'CREATE_DIRECT_REQUEST' | Translate: (translationService.$currentTranslation | async)}}
          </label>
          <!-- Выглядит хуже -->
          <!-- <span>
            <label>{{'Ich habe eine Übersetzungsanfrage.' | Translate: (translationService.$currentTranslation |
              async)}}</label>
            <label class="color-primary" style="cursor:pointer;" (click)="goToRegister('requestor')">
              {{'Hier registrieren' | Translate: (translationService.$currentTranslation | async)}}
            </label>
          </span>
          <span style="display:block;">
            <label>
              {{'Ich möchte als Übersetzer/Übersetzerin helfen.' | Translate: (translationService.$currentTranslation | async)}}
            </label>
            <label class="color-primary" style="cursor:pointer" (click)="goToRegister('translator')">
              {{'Hier registrieren' | Translate: (translationService.$currentTranslation | async)}}
            </label>
          </span>
          <label>Sie haben bereits ein Konto? Melden Sie sich mit Ihrer E-Mail-Adresse und Ihrem Passwort an.</label> -->
        </h3>

        <mat-form-field appearance="outline" class="w-100">
          <mat-label>{{'LABEL_EMAIL' | Translate: (translationService.$currentTranslation | async)}}</mat-label>
          <input matInput type='email' [(ngModel)]="email">
        </mat-form-field>

        <mat-form-field appearance="outline" class="w-100">
          <mat-label>{{'PASSWORD' | Translate: (translationService.$currentTranslation | async)}}</mat-label>
          <input matInput [type]="hidePassword ? 'password' : 'text'" [(ngModel)]="password">
          <button mat-icon-button matSuffix (click)="hidePassword = !hidePassword" [attr.aria-label]="'Hide password'"
            [attr.aria-pressed]="hidePassword">
            <mat-icon>{{hidePassword ? 'visibility_off' : 'visibility'}}</mat-icon>
          </button>
        </mat-form-field>

        <button mat-raised-button color="primary" class="w-100" [disabled]="!email || !password"
          (click)="login()">{{'LABEL_CONTINUE' | Translate: (translationService.$currentTranslation | async)}}
        </button>
        <label class="color-primary w-100 text-sm" style="cursor:pointer; margin-top: 0.3rem;"
          (click)="forgotPassword()">{{'LABEL_FORGOT_YOUR_PASSWORD' | Translate: (translationService.$currentTranslation
          |
          async)}}</label>

          <section class="footer" *ngIf="globalsService.isMobileView">
            <a class="color-primary" href="https://translatorsagainstwar.de/datenschutzerklaerung/">
              {{"PRIVACY" | Translate: (translationService.$currentTranslation | async)}}
            </a>
            <a class="color-primary" href="https://translatorsagainstwar.de/impressum/">
              {{"IMPRINT" | Translate: (translationService.$currentTranslation | async)}}
            </a>
          </section>
      </div>           
    </div>
  </section>
</div>