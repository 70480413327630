import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { API_RESPONSE, Language } from '../../interfaces';
import { GlobalsService } from '../../services/globals.service';
import { HttpService } from '../../services/http-service';
import { NavigationService } from '../../services/navigation.service';
import { ToastService } from '../../services/toast-service';
import { TranslationService } from '../../services/translation-service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {
  hidePassword = true;
  email = '';
  password = ''; // HASH the password
  constructor(
    public translationService: TranslationService,
    private toastService: ToastService,
    private httpService: HttpService,
    private router: Router,
    private navService: NavigationService,
    public globalsService: GlobalsService,
  ) {
    // const navigationState =  this.router.getCurrentNavigation()?.extras?.state;    
  }

  async ngOnInit(): Promise<void> {
    const navigationState = history.state;
    // use navigationState to to autologin the new registered user
    if (navigationState?.email) {
      this.email = navigationState.email;
      this.password = navigationState.password;
      await this.login();
    }
    this.navService.showHeaderNavigation.next(false);
    this.navService.showFooterNavigation.next(false);
  }

  goToRegister(registerAs?: string) {
    this.router.navigate(['sign-up', registerAs]);
  }
  goToDirectCreate() {
    this.router.navigate(['create-direct-request']);
  }

  async login() {
    const loginResp = await this.httpService.get('login', { email: this.email, password: this.password }) as API_RESPONSE;
    if (loginResp.success && loginResp.data) {
      localStorage.setItem('sessionid', loginResp.data.sessionid);
      localStorage.setItem('role', loginResp.data.role);
      this.httpService.sessionid = loginResp.data.sessionid;
      this.httpService.role = loginResp.data.role;
      if (loginResp.data.userLanguage && loginResp.data.userLanguage !== this.translationService.userLanguage.friendlyName) {
        // if user changed the language on the Login page, update the language in BE after user is logged in
        // but only in case if he has a DEFAULT language before
        if(loginResp.data.userLanguage === Language.GERMAN){
          await this.httpService.post('changeUserLanguage', { language: this.translationService.userLanguage.friendlyName });
          this.translationService.changeLanguage(this.translationService.userLanguage.friendlyName);
        }else{
          this.translationService.changeLanguage(loginResp.data.userLanguage);
        }
      }
      this.router.navigate(['translation-requests']);
    } else {
      console.log('%cERROR', 'background: red', ': LoginComponent -> login -> loginResp', loginResp);
      this.toastService.showErrorSnack(loginResp.message, 'OK');
    }
  }

  // TODO: FUTURE: add restore password => require extra view or adjustment of current to select emailAddress and send the link 
  // to user email with the restore link, which provides user to another view allowing him to set the password in BE
  forgotPassword() {
    this.router.navigate(['forgot-password']);
  }

  ngOnDestroy(): void {
    this.navService.showHeaderNavigation.next(true);
    this.navService.showFooterNavigation.next(true);
  }
}
