import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { API_RESPONSE, Language, RequestStatus, Role, TranslationRequest, TranslatorSkill, User } from '../../../interfaces';
import { HttpService } from '../../../services/http-service';
import { ToastService } from '../../../services/toast-service';
import { TranslationService } from '../../../services/translation-service';
import { NavigationService } from '../../../services/navigation.service';

@Component({
  selector: 'app-create-translation-request',
  templateUrl: './create-translation-request.component.html',
  styleUrls: ['./create-translation-request.component.scss']
})
export class CreateTranslationRequestComponent implements OnInit {
  viewType: 'create' | 'edit' = 'create';
  requestType = TranslatorSkill.TRANSLATE; // 'TRANSLATE' | 'INTERPRET'
  languageFrom = Language.UKRAINIAN; // 'RUSSIAN' | 'UKRAINIAN' 
  languageTo = Language.GERMAN;

  // ADMIN props
  translatorsList: User[];
  selectedStatus: string;
  selectedTranslator: User;
  comment: string; // comment from admin => visible to him and translator

  // enums
  USER_ROLE = Role;
  REQUEST_STATUS = RequestStatus;

  // permissionRequired = false;
  requestDescription: string;
  // TranslateDetails
  textType: string;
  textAmountOfPages: number;
  handwritten: boolean;
  medicalTranslation: boolean;

  // InterpretDetails
  appointmentDateTime: Date;
  appointmentDuration: number;
  appointmentAddress: string;
  appointmentTheme: string;
  phoneInterpretation: boolean;
  flexibleDate: boolean;

  MIN_DESCRIPTION_LENGTH = 1; //100;

  isCreateRequestLoading = false;
  isDirectCreate = false;
  userName: string;
  userEmail: string;
  emailRegex =  /^[^\s@]+@[^\s@]+\.[^\s@]+$/

  acceptedTerms: boolean;
  acceptedAGB: boolean;
  acceptedThirdCountryTranslations: boolean; // user need to agree with the translations coming from outside of EU

  constructor(
    public translationService: TranslationService,
    private toastService: ToastService,
    public httpService: HttpService,
    private route: ActivatedRoute,
    private router: Router,
    @Optional() public dialogRef: MatDialogRef<CreateTranslationRequestComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public requestData: TranslationRequest,
    private navService: NavigationService,  
  ) {
    const routePath = this.route.snapshot?.routeConfig?.path;
    this.isDirectCreate = (routePath === 'create-direct-request');
    if (this.isDirectCreate) {
      this.translationService.changeLanguage(Language.UKRAINIAN);
    }
  }

  async ngOnInit(): Promise<void> {
    this.navService.showFooterNavigation.next(false);
    if (this.requestData) {
      this.viewType = 'edit';
      this.requestType = this.requestData.requestedSkill.split('_')[1] as TranslatorSkill;
      this.languageFrom = this.requestData.languageFrom;
      this.languageTo = this.requestData.languageTo;
      this.comment = this.requestData.comment;
      // this.permissionRequired = this.requestData.requestedSkill.includes('OFFICIAL');
      this.requestDescription = this.requestData.description;
      if (this.requestType === 'TRANSLATE') {
        this.textType = this.requestData.textType;
        this.textAmountOfPages = this.requestData.textAmountOfPages;
        this.handwritten = this.requestData.handwritten;
        this.medicalTranslation = this.requestData.medicalTranslation;
      } else {
        this.appointmentDateTime = this.requestData.appointmentDateTime;
        this.appointmentDuration = this.requestData.appointmentDuration;
        this.appointmentAddress = this.requestData.appointmentAddress;
        this.appointmentTheme = this.requestData.appointmentTheme;
        this.phoneInterpretation = this.requestData.phoneInterpretation;
        this.flexibleDate = this.requestData.flexibleDate;
      }
    } else {
      this.viewType = 'create';
    }

    if (this.httpService.role === Role.ADMIN) {
      this.selectedStatus = this.requestData.status;
      this.selectedTranslator = this.requestData.translator;
      // get translators list
      await this.httpService.get('getTranslatorsList').then((response: API_RESPONSE) => {
        if (response.success) {
          this.translatorsList = response.data;
        } else {
          this.toastService.showErrorSnack(response.message, 'Error');
        }
      });
    }
  }


  createTranslationRequest() {
    this.isCreateRequestLoading = true;
    const requestData = this.prepareRequestData();
    let postBody;
    if (this.isDirectCreate) {
      const userData = this.prepareDirectUser();
      postBody = { requestData: requestData, userData: userData };
      this.httpService.post('createUserAndRequest', postBody).then((response) => {
        if (response.success) {
          // TODO: translate message
          // this.toastService.showSuccessSnack('Translation request and user created successfully', 'Success');
          this.toastService.showSuccessSnack(response.message, 'Success');
          this.toastService.showInfoSnack('DIRECT_REQUEST_INSTRUCTIONS', 'UNDERSTOOD', 999999);
          this.router.navigate(['login']);
        } else {
          this.isCreateRequestLoading = false;
          this.toastService.showErrorSnack(response.message, 'Error');
        }
      });
    } else {
      this.httpService.post('createTranslationRequest', requestData).then((response) => {
        if (response.success) {
          // TODO: translate message
          this.toastService.showSuccessSnack(response.message, 'Success');
          this.dialogRef.close({ refreshList: true });
        } else {
          this.isCreateRequestLoading = false;
          this.toastService.showErrorSnack(response.message, 'Error');
        }
      });
    }
  }

  updateTranslationRequest() {
    this.isCreateRequestLoading = true;
    const requestData = this.prepareRequestData()
    this.httpService.post('updateTranslationRequest', requestData, { requestNr: this.requestData.Nr }).then((response) => {
      if (response.success) {
        this.isCreateRequestLoading = false;
        // this.toastService.showSuccessSnack('Translation request edited successfully', 'Success');
        this.toastService.showSuccessSnack(response.message, 'Success');
        this.dialogRef.close({ refreshList: true });
      } else {
        this.isCreateRequestLoading = false;
        this.toastService.showErrorSnack(response.message, 'Error');
      }
    });
  }

  prepareRequestData(): TranslationRequest {
    const requestedSkill = `${this.languageFrom === 'GERMAN' ? this.languageTo : this.languageFrom}_${this.requestType}`;
    const requestData = {
      // requestedSkill: this.languageFrom + '_' + 'TO' + '_' + this.languageTo + '_' + this.requestType, // + (this.permissionRequired ? '_OFFICIAL' : ''),
      requestedSkill: requestedSkill,
      status: this.selectedStatus || "WAITING", // selectedStatus used in case of ADMIN only
      description: this.requestDescription,
      languageFrom: this.languageFrom,
      languageTo: this.languageTo,
    } as TranslationRequest;
    if (this.requestType === 'TRANSLATE') {
      requestData.textType = this.textType;
      requestData.textAmountOfPages = this.textAmountOfPages;
      requestData.handwritten = this.handwritten;
      requestData.medicalTranslation = this.medicalTranslation;
    } else {
      requestData.appointmentDateTime = this.appointmentDateTime;
      requestData.appointmentDuration = this.appointmentDuration;
      requestData.appointmentAddress = this.appointmentAddress;
      requestData.appointmentTheme = this.appointmentTheme;
      requestData.phoneInterpretation = this.phoneInterpretation;
      requestData.flexibleDate = this.flexibleDate;
    }
    // ADMIN ONLY
    if (this.selectedTranslator) {
      requestData.translator = this.selectedTranslator;
    }
    if (this.comment) requestData.comment = this.comment;
    return requestData;
  }

  prepareDirectUser(): User {
    // DIRECT CREATE ONLY    
    const user = {
      name: this.userName,
      email: this.userEmail,
      role: Role.REQUESTOR
    } as User;
    return user;
  }

  compareFn(a: User, b: User): boolean {
    return a.email === b.email;
  }

  translatorObjTrackByFn(index: number, item: User) {
    return item.email;
  }

  closeDialog() {
    this.dialogRef.close();
  }
}
