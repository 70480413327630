import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { Role } from "../interfaces";
import { ToastService } from "./toast-service";

@Injectable({ providedIn: 'root' })
export class HttpService {
  domain: string;
  sessionid: string;
  role: Role;

  appCalls = {
    login: '$domain$/TAW_API/users/login?email=$email$&password=$password$', // GET
    register: '$domain$/TAW_API/users/createUser', // POST
    getUserProfile: '$domain$/TAW_API/users/getUserProfile?sessionid=$sessionid$', // GET
    updateUserProfile: '$domain$/TAW_API/users/updateUserProfile?sessionid=$sessionid$', // POST
    createUserVerificationCode: '$domain$/TAW_API/users/createUserVerificationCode', // PSOT
    checkUserVerificationCode: '$domain$/TAW_API/users/checkUserVerificationCode', // POST
    changeUserPassword: '$domain$/TAW_API/users/changeUserPassword', // POST
    getTranslationRequests: '$domain$/TAW_API/request/getTranslationRequests?filterType=$filterType$&sessionid=$sessionid$', // GET
    createTranslationRequest: '$domain$/TAW_API/request/createTranslationRequest?sessionid=$sessionid$', // POST
    createUserAndRequest: '$domain$/TAW_API/request/createUserAndRequest', // POST
    updateTranslationRequest: '$domain$/TAW_API/request/updateTranslationRequest/$requestNr$?sessionid=$sessionid$', // POST
    getRequestorContactsByRequestNr: '$domain$/TAW_API/request/getRequestorContactsByRequestNr/$requestNr$/?sessionid=$sessionid$', // GET
    assignRequest: '$domain$/TAW_API/request/assignRequest/$requestNr$/?sessionid=$sessionid$', // GET
    changeRequestStatus: '$domain$/TAW_API/request/changeRequestStatus/$requestNr$/?status=$status$&sessionid=$sessionid$', // GET
    deleteRequest: '$domain$/TAW_API/request/deleteRequest/$requestNr$/?sessionid=$sessionid$', // GET
    changeUserLanguage: '$domain$/TAW_API/users/changeUserLanguage?sessionid=$sessionid$', // POST
    deleteUserAccount: '$domain$/TAW_API/users/deleteUserAccount?sessionid=$sessionid$', // GET
    /////////////// COMBOBOX - DATA PROVIDERS ///////////////
    getTranslatorsList: '$domain$/TAW_API/users/getTranslatorsList?sessionid=$sessionid$', // GET
    //////////////// ADMIN ////////////////
    getRequestsAsExcel: '$domain$/TAW_API/excel/getRequestsAsExcel?sessionid=$sessionid$&dateFrom=$dateFrom$&dateTo=$dateTo$',
    getTranslatorsAsExcel: '$domain$/TAW_API/excel/getTranslatorsAsExcel?sessionid=$sessionid$',
    getRequestCountAsExcel: '$domain$/TAW_API/excel/getRequestCountAsExcel?sessionid=$sessionid$',
    getRequestCount: '$domain$/TAW_API/request/getRequestCount?sessionid=$sessionid$',

  }
  constructor(
    private toastService: ToastService,
    private httpClient: HttpClient
  ) {
    // restore sessionId from localStorage if exists
    this.sessionid = localStorage.getItem('sessionid') || null;
    this.role = localStorage.getItem('role') as Role || null;
    const currentDomain = new URL(window.location.href).origin;
    this.domain = currentDomain.includes('localhost') ? 'http://localhost:5300' : environment.domain;
    // this.domain = environment.domain;
  }

  isAuthenticated(): boolean {
    return !!this.sessionid;
  }

  async get(callName: string, queryParams?: any): Promise<any> {
    console.log('%cLOGGER', 'background: blue', ' => get => queryParams', queryParams);
    // if (!this.appCalls[callName]) return console.error('%cERROR', 'background: red', 'call with the name:', callName, 'not found');
    // let url = this.appCalls[callName].replace('$domain$', this.domain).replace('$sessionid$', this.sessionid);
    // url = this.applyQuery(url, queryParams);
    // console.log('%cLOGGER', 'background: blue', ': HttpService -> url', url);
    // if ((/\$[A-z0-9]*\$/).test(url)) {
    //   console.log('%cERROR', 'background: red', ': Parse URL Error: not all query params could be applyed', url);
    //   this.toastService.showErrorSnack(url, 'Parse URL Error: not all query params could be applyed');
    // }

    const url = this.buildUrl(callName, queryParams);
    if (!url) return;
    return await this.httpClient.get(url).toPromise().catch(err => {
      console.log('%cERROR', 'background: red', ': HttpService -> err', err);
      this.toastService.showErrorSnack(err.error.message, 'Error');
      return err.data || null;
    });
  }

  buildUrl(callName: string, queryParams?: any): string {
    if (!this.appCalls[callName]) {
      console.error('%cERROR', 'background: red', 'call with the name:', callName, 'not found');
      return null;
    }
    this.sessionid = this.sessionid || queryParams.sessionid;
    let url = this.appCalls[callName].replace('$domain$', this.domain).replace('$sessionid$', this.sessionid);
    url = this.applyQuery(url, queryParams);
    console.log('%cLOGGER', 'background: blue', ': HttpService -> url', url);
    if ((/\$[A-z0-9]*\$/).test(url)) {
      console.log('%cERROR', 'background: red', ': Parse URL Error: not all query params could be applyed', url);
      this.toastService.showErrorSnack(url, 'Parse URL Error: not all query params could be applyed');
      return null;
    }
    return url;
  }

  async post(callName: string, postData: any, queryParams?: any): Promise<any> {
    if (!this.appCalls[callName]) return console.error('%cERROR', 'background: red', 'call with the name:', callName, 'not found');
    let url = this.appCalls[callName].replace('$domain$', this.domain).replace('$sessionid$', this.sessionid);
    console.log('%cLOGGER', 'background: brown', ': HttpService -> url', url);
    url = this.applyQuery(url, queryParams);
    return this.httpClient.post(url, postData).toPromise();
  }

  applyQuery(url: string, queryParams?: any): string {
    if (!queryParams) return url;
    for (const key in queryParams) {
      if (queryParams.hasOwnProperty(key) && queryParams[key]) {
        const RegExToReplace = new RegExp('\\$' + key + '\\$', 'g');
        // escape queryParam to avoid bugs
        let encodedQueryParam = escape(queryParams[key]);
        // // FUTURE: hash password
        // if(key === 'password'){
        //   encodedQueryParam = this.cyrb53hash(queryParams[key]);
        //   console.log('%cLOGGER', 'background: green',' => applyQuery => hashed', encodedQueryParam);
        // }
        url = url.replace(RegExToReplace, encodedQueryParam);
      }
    }
    return url;
  }

  // FUTURE: UNUSED: generate hash 
  cyrb53hash(str, seed = 3) {
    let h1 = 0xdeadbeef ^ seed,
      h2 = 0x41c6ce57 ^ seed;
    for (let i = 0, ch; i < str.length; i++) {
      ch = str.charCodeAt(i);
      h1 = Math.imul(h1 ^ ch, 2654435761);
      h2 = Math.imul(h2 ^ ch, 1597334677);
    }

    h1 = Math.imul(h1 ^ (h1 >>> 16), 2246822507) ^ Math.imul(h2 ^ (h2 >>> 13), 3266489909);
    h2 = Math.imul(h2 ^ (h2 >>> 16), 2246822507) ^ Math.imul(h1 ^ (h1 >>> 13), 3266489909);

    return (h2 >>> 0).toString(16).padStart(8, '0') + (h1 >>> 0).toString(16).padStart(8, '0');
  };

}