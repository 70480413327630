import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { API_RESPONSE, FilterType, RequestStatus, statusOrder, TranslationRequest } from '../../interfaces';
import { HttpService } from '../../services/http-service';
import { NavigationService } from '../../services/navigation.service';
import { ToastService } from '../../services/toast-service';
import { TranslationService } from '../../services/translation-service';
import { CreateTranslationRequestComponent } from './create-translation-request/create-translation-request.component';

const REQUEST_LIST_STATE = {
  0: 'MATCHING',
  1: 'OWN'
}
@Component({
  selector: 'app-translation-request-list',
  templateUrl: './translation-request-list.component.html',
  styleUrls: ['./translation-request-list.component.scss']
})
export class TranslationRequestListComponent implements OnInit {
  requestList: TranslationRequest[] = [];
  selectedCard = null;
  constructor(
    public translationService: TranslationService,
    private toastService: ToastService,
    public httpService: HttpService,
    private dialog: MatDialog,
    private navigationService: NavigationService,
  ) { }

  ngOnInit(): void {
    this.navigationService.showHeaderNavigation.next(true);
    this.navigationService.showFooterNavigation.next(true);
    this.getTranslationRequests(FilterType.MATCHING);
    // refresh the request list when the user updated the profile (in case he changed skills)
    this.navigationService.refreshRequestList.subscribe(() => {
      this.getTranslationRequests(FilterType.MATCHING);
    });
  }

  onTabChange(event){
    this.getTranslationRequests(REQUEST_LIST_STATE[event.index]);
  }

  async getTranslationRequests(filterType: FilterType) {
    const getTranslationRequestsResp = await this.httpService.get('getTranslationRequests', { filterType: filterType }) as API_RESPONSE;
    if (getTranslationRequestsResp.success && getTranslationRequestsResp.data) {
      getTranslationRequestsResp.data.forEach(request => {
        request.requestType = request.requestedSkill.includes('INTERPRET') ? 'INTERPRET' : 'TRANSLATE';
        // request.isOfficial = request.requestedSkill.includes('OFFICIAL');
      });
      this.requestList = getTranslationRequestsResp.data.sort((a,b) => {
        // sort by request status WAITING => IN_PROGRESS => DONE => CLOSED and then by request creation date
        if( a.status === b.status ){
          return a.createdAt > b.createdAt ? -1 : 1;
        }
        return statusOrder[a.status] - statusOrder[b.status];
      });
    } else {
      this.toastService.showErrorSnack(getTranslationRequestsResp.message, 'OK');
    }
}

// create or edit translation request
createRequest(request?: TranslationRequest) {
    const createRequestDialogRef = this.dialog.open(CreateTranslationRequestComponent, {
      data: request || null,
      width: 'fit-content',
      height: 'auto',
      minWidth: '25rem',
      panelClass: 'create-request-dialog',
    });
    createRequestDialogRef.afterClosed().subscribe((result) => {
      if(result?.refreshList){
        // refresh after Requets creation
        this.getTranslationRequests(FilterType.OWN);
      }
    })
  }

}
