import { Injectable } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { TranslationService } from "./translation-service";

@Injectable({ providedIn: 'root' })
export class ToastService {

  constructor(
    private _snackBar: MatSnackBar,
    private translationService: TranslationService
  ) {

  }

  showSnackBarMessage(message: string, action: string = 'CLOSE', duration?: number) {
    message = this.translationService.translateMessage(message);
    action = this.translationService.translateMessage(action);
    this._snackBar.open(message, action, { duration: duration || 2000, panelClass: ['bg-black', 'pink-theme'] });
  }

  showSuccessSnack(message: string, action: string = 'CLOSE', duration?: number) {
    message = this.translationService.translateMessage(message);
    action = this.translationService.translateMessage(action);
    this._snackBar.open(message, action, { duration: duration || 2000, panelClass: ['snackbar','success-snackbar'] });
  }

  showErrorSnack(message: string, action: string = 'CLOSE',) {
    message = this.translationService.translateMessage(message);
    action = this.translationService.translateMessage(action);
    // const dayDuration = 24 * 60 * 60 * 100;
    this._snackBar.open(message, action, { duration: 5000, panelClass: ['bg-error'] });
  }

  showWarnSnack(message: string, action: string = 'CLOSE', duration?: number) {
    message = this.translationService.translateMessage(message);
    action = this.translationService.translateMessage(action);
    this._snackBar.open(message, action, { duration: duration || 5000, panelClass: ['bg-warn'] });
  }

  showInfoSnack(message: string, action: string = 'CLOSE',duration?: number) {
    message = this.translationService.translateMessage(message);
    action = this.translationService.translateMessage(action);
    this._snackBar.open(message, action, { duration: duration || 2000, panelClass: ['snackbar','info-snackbar']});
  }

}