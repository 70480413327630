<div class="create-request-wrapper" [ngClass]="{ 'extra-padding': isDirectCreate }">
  <mat-icon
    *ngIf="!isDirectCreate"
    class="close-btn"
    style="position: absolute; top: 0; right: 0"
    (click)="closeDialog()">
    close</mat-icon
  >

  <div name="direct-create-user-data-section" *ngIf="isDirectCreate">
    <h2>{{ 'LOGIN_DATA' | Translate : (translationService.$currentTranslation | async) }}</h2>
    <section class="request-select-wrapper">
      <mat-form-field appearance="outline">
        <mat-label>{{ 'LABEL_NAME' | Translate : (translationService.$currentTranslation | async) }}</mat-label>
        <mat-icon matPrefix class="prefix-icon">person</mat-icon>
        <input matInput [(ngModel)]="userName" placeholder="..." required />
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>{{ 'LABEL_EMAIL' | Translate : (translationService.$currentTranslation | async) }}</mat-label>
        <mat-icon matPrefix class="prefix-icon">mail</mat-icon>
        <input
          matInput
          [(ngModel)]="userEmail"
          type="email"
          placeholder="..."
          required
          pattern="^[^\s@]+@[^\s@]+\.[^\s@]+$" />
      </mat-form-field>
    </section>
  </div>

  <h2>{{ 'NEW_REQUEST' | Translate : (translationService.$currentTranslation | async) }}</h2>
  <section class="request-select-wrapper">
    <mat-form-field appearance="outline">
      <mat-label>{{ 'REQUEST_TYPE' | Translate : (translationService.$currentTranslation | async) }}</mat-label>
      <mat-select [(value)]="requestType">
        <mat-option value="TRANSLATE"
          >{{ 'TRANSLATE' | Translate : (translationService.$currentTranslation | async) }}
        </mat-option>
        <mat-option value="INTERPRET"
          >{{ 'INTERPRET' | Translate : (translationService.$currentTranslation | async) }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <div class="grid-2">
      <mat-form-field appearance="outline">
        <mat-label>{{ 'LANGUAGE_FROM' | Translate : (translationService.$currentTranslation | async) }}</mat-label>
        <mat-select [(value)]="languageFrom">
          <mat-option value="RUSSIAN" *ngIf="languageTo !== 'RUSSIAN'"
            >{{ 'RUSSIAN' | Translate : (translationService.$currentTranslation | async) }}
          </mat-option>
          <mat-option value="UKRAINIAN" *ngIf="languageTo !== 'UKRAINIAN'"
            >{{ 'UKRAINIAN' | Translate : (translationService.$currentTranslation | async) }}
          </mat-option>
          <mat-option value="GERMAN" *ngIf="languageTo !== 'GERMAN'"
            >{{ 'GERMAN' | Translate : (translationService.$currentTranslation | async) }}
          </mat-option>
          <mat-option *ngIf="requestType === 'INTERPRET'" value="UKRAINIAN_OR_RUSSIAN"
            >{{ 'UKRAINIAN_OR_RUSSIAN' | Translate : (translationService.$currentTranslation | async) }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>{{ 'LANGUAGE_TO' | Translate : (translationService.$currentTranslation | async) }}</mat-label>
        <mat-select [(value)]="languageTo">
          <mat-option value="RUSSIAN" *ngIf="languageFrom !== 'RUSSIAN'"
            >{{ 'RUSSIAN' | Translate : (translationService.$currentTranslation | async) }}
          </mat-option>
          <mat-option value="UKRAINIAN" *ngIf="languageFrom !== 'UKRAINIAN'"
            >{{ 'UKRAINIAN' | Translate : (translationService.$currentTranslation | async) }}
          </mat-option>
          <mat-option value="GERMAN" *ngIf="languageFrom !== 'GERMAN'"
            >{{ 'GERMAN' | Translate : (translationService.$currentTranslation | async) }}
          </mat-option>
          <mat-option
            *ngIf="requestType === 'INTERPRET' && languageFrom !== 'UKRAINIAN_OR_RUSSIAN'"
            value="UKRAINIAN_OR_RUSSIAN"
            >{{ 'UKRAINIAN_OR_RUSSIAN' | Translate : (translationService.$currentTranslation | async) }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </section>

  <!---------------------------------- ADMIN SECTION --------------------------------->
  <section *ngIf="httpService.role === USER_ROLE.ADMIN" class="fields-grid">
    <mat-form-field appearance="outline">
      <mat-label>{{ 'STATUS_SELECT' | Translate : (translationService.$currentTranslation | async) }}</mat-label>
      <mat-select [(value)]="selectedStatus">
        <mat-option *ngFor="let status of REQUEST_STATUS | keyvalue" [value]="status.value"
          >{{ status.value | Translate : (translationService.$currentTranslation | async) }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>{{ 'TRANSLATOR_SELECT' | Translate : (translationService.$currentTranslation | async) }}</mat-label>
      <mat-select [(value)]="selectedTranslator" [compareWith]="compareFn">
        <mat-option
          *ngFor="let translatorObj of translatorsList; trackBy: translatorObjTrackByFn"
          [value]="translatorObj"
          >{{ translatorObj.name + ': ' + translatorObj.email }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field appearance="outline" style="grid-column: span 2">
      <mat-label>{{ 'ADMIN_COMMENT' | Translate : (translationService.$currentTranslation | async) }}</mat-label>
      <input
        [(ngModel)]="comment"
        matInput
        [placeholder]="'ADMIN_COMMENT' | Translate : (translationService.$currentTranslation | async)"
        required />
    </mat-form-field>
  </section>
  <!---------------------------------- ADMIN SECTION END --------------------------------->

  <!-- <mat-slide-toggle [(ngModel)]="permissionRequired" color="primary" style="margin-bottom: 1rem;">
    {{ "AUTHORIZATION_REQUIRED" | Translate: (translationService.$currentTranslation | async) }}
  </mat-slide-toggle> -->

  <section class="fields-grid">
    <!-- TRANSLATE REQEUST DETAILS -->
    <ng-container *ngIf="requestType === 'TRANSLATE'">
      <mat-form-field appearance="outline">
        <mat-label>{{ 'TEXT_TYPE' | Translate : (translationService.$currentTranslation | async) }}</mat-label>
        <input
          [(ngModel)]="textType"
          matInput
          [placeholder]="'TEXT_TYPE_EXAMPLE' | Translate : (translationService.$currentTranslation | async)"
          required />
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>{{ 'AMOUNT_OF_PAGES' | Translate : (translationService.$currentTranslation | async) }}</mat-label>
        <input
          [(ngModel)]="textAmountOfPages"
          type="number"
          onkeydown="event.key==='.' || event.key==='-' ? event.preventDefault(): event"
          min="1"
          max="10"
          matInput
          required />
      </mat-form-field>

      <mat-slide-toggle [(ngModel)]="handwritten" color="primary" style="margin-bottom: 1rem">
        {{ 'HANDWRITTEN' | Translate : (translationService.$currentTranslation | async) }}
      </mat-slide-toggle>

      <mat-slide-toggle [(ngModel)]="medicalTranslation" color="primary" style="margin-bottom: 1rem">
        {{ 'MEDICAL_TRANSLATION' | Translate : (translationService.$currentTranslation | async) }}
      </mat-slide-toggle>
    </ng-container>

    <!-- INTERPRET REQEUST DETAILS -->
    <ng-container *ngIf="requestType === 'INTERPRET'">
      <mat-form-field appearance="outline">
        <mat-label>{{ 'APPOINTMENT_THEME' | Translate : (translationService.$currentTranslation | async) }}</mat-label>
        <input [(ngModel)]="appointmentTheme" type="text" matInput required />
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label
          >{{ 'APPOINTMENT_DATE_TIME' | Translate : (translationService.$currentTranslation | async) }}
        </mat-label>
        <input matInput [matDatepicker]="pdt" [(ngModel)]="appointmentDateTime" [required]="!flexibleDate" />
        <mat-datepicker-toggle matSuffix [for]="pdt"></mat-datepicker-toggle>
        <mat-datepicker #pdt type="datetime" [twelveHour]="false" clockStep="5" [touchUi]="true">
          <mat-datepicker-actions>
            <button mat-button matDatepickerCancel color="primary">
              {{ 'CLOSE' | Translate : (translationService.$currentTranslation | async) }}
            </button>
            <button mat-button matDatepickerApply color="primary">
              {{ 'ACCEPT' | Translate : (translationService.$currentTranslation | async) }}
            </button>
          </mat-datepicker-actions>
        </mat-datepicker>
      </mat-form-field>

      <mat-form-field appearance="outline" class="sufix-no-margin">
        <mat-label
          >{{ 'APPOINTMENT_DURATION' | Translate : (translationService.$currentTranslation | async) }}
        </mat-label>
        <input
          [(ngModel)]="appointmentDuration"
          type="number"
          min="0"
          onkeydown="event.key==='-' ? event.preventDefault(): event"
          matInput
          [placeholder]="'NUMBER_OF_HOURS' | Translate : (translationService.$currentTranslation | async)"
          required />
        <div matSuffix>{{ 'HOURS' | Translate : (translationService.$currentTranslation | async) }}</div>
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>{{
          'APPOINTMENT_ADDRESS' | Translate : (translationService.$currentTranslation | async)
        }}</mat-label>
        <input [(ngModel)]="appointmentAddress" type="text" matInput [required]="!phoneInterpretation" />
      </mat-form-field>

      <div style="margin-bottom: 1rem">
        <mat-slide-toggle [(ngModel)]="phoneInterpretation" color="primary" >
          {{ 'PHONE_INTERPRETATION' | Translate : (translationService.$currentTranslation | async) }}
        </mat-slide-toggle>
        <span class="toggle-hint"
          >{{'PHONE_INTERPRETATION_HINT' | Translate : (translationService.$currentTranslation | async)}}</span
        >
      </div>

      <mat-slide-toggle [(ngModel)]="flexibleDate" color="primary" style="margin-bottom: 1rem">
        {{ 'FLEXIBLE_DATE' | Translate : (translationService.$currentTranslation | async) }}
      </mat-slide-toggle>
    </ng-container>
  </section>

  <!-- REQUEST DESCRIPTION -->
  <mat-form-field appearance="outline" style="display: block">
    <!-- <mat-label>{{ "REQUEST_TEXT MIN_100_CHARACTERS" | Translate: (translationService.$currentTranslation | async) }} -->
    <mat-label>{{ 'REQUEST_TEXT' | Translate : (translationService.$currentTranslation | async) }} </mat-label>
    <textarea
      [(ngModel)]="requestDescription"
      matInput
      rows="2"
      [minlength]="MIN_DESCRIPTION_LENGTH"
      required></textarea>
    <!-- <mat-hint>{{ "MIN_100_CHARACTERS" | Translate: (translationService.$currentTranslation | async) }}</mat-hint> -->
    <!-- <mat-hint>{{"REQUEST_DESCRIPTION_HINT" | Translate: (translationService.$currentTranslation | async)}}</mat-hint> -->
  </mat-form-field>

  <section class="hint-card">
    <ul>
      <li class="text-sm">
        {{ 'REQUEST_DESCRIPTION_HINT' | Translate : (translationService.$currentTranslation | async) }}
      </li>
      <li class="text-sm" style="margin-top: 1rem; font-style: italic">
        {{ 'DOCUMENT_UPLOAD_HINT' | Translate : (translationService.$currentTranslation | async) }}
      </li>
    </ul>
  </section>

  <!-- Accept Terms -->
  <section class="terms-acceptance">
    <mat-checkbox [(ngModel)]="acceptedTerms">
      <label
        (click)="acceptedTerms = !acceptedTerms"
        [innerHTML]="
          'ACCEPT_TERMS'
            | Translate
              : (translationService.$currentTranslation | async)
              : 'https://translatorsagainstwar.de/datenschutzerklaerung/'
        "></label>
    </mat-checkbox>
    <mat-checkbox [(ngModel)]="acceptedAGB">
      <label
        (click)="acceptedAGB = !acceptedAGB"
        [innerHTML]="
          'ACCEPT_AGB'
            | Translate
              : (translationService.$currentTranslation | async)
              : '../../../assets/files/AGB_TranslatorsAgainstWar.pdf'
        "></label>
    </mat-checkbox>
    <mat-checkbox [(ngModel)]="acceptedThirdCountryTranslations">
      {{ 'ACCEPT_THIRD_COUNTRY_TRANSLATIONS' | Translate : (translationService.$currentTranslation | async) }}
    </mat-checkbox>
  </section>

  <section class="actions-section">
    <button mat-button color="warn" (click)="closeDialog()">
      {{ 'CLOSE' | Translate : (translationService.$currentTranslation | async) }}
    </button>
    <button
      mat-button
      color="primary"
      *ngIf="viewType === 'create'"
      (click)="createTranslationRequest()"
      [disabled]="
        isCreateRequestLoading ||
        !languageFrom ||
        !languageTo ||
        !requestType ||
        !requestDescription ||
        requestDescription.length < MIN_DESCRIPTION_LENGTH ||
        !acceptedTerms ||
        !acceptedAGB ||
        !acceptedThirdCountryTranslations ||
        (requestType === 'INTERPRET' && (!appointmentTheme || !appointmentDuration || !appointmentAddress)) ||
        (requestType === 'TRANSLATE' && (!textType || !textAmountOfPages)) ||
        (isDirectCreate && (!userEmail || !emailRegex.test(userEmail) || !userName))
      ">
      {{ 'CREATE_NEW_REQUEST' | Translate : (translationService.$currentTranslation | async) }}
    </button>
    <button
      mat-button
      color="primary"
      *ngIf="viewType === 'edit'"
      (click)="updateTranslationRequest()"
      [disabled]="
        isCreateRequestLoading ||
        !languageFrom ||
        !languageTo ||
        !requestType ||
        !requestDescription ||
        requestDescription.length < MIN_DESCRIPTION_LENGTH ||
        (requestType === 'INTERPRET' && (!appointmentTheme || !appointmentDuration || !appointmentAddress)) ||
        (requestType === 'TRANSLATE' && (!textType || !textAmountOfPages))
      ">
      {{ 'UPDATE_REQUEST' | Translate : (translationService.$currentTranslation | async) }}
    </button>
  </section>
</div>
