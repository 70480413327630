import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { API_RESPONSE, FilterType, RequestStatus, Role, TranslationRequest } from '../../../interfaces';
import { ConfirmationDialogService } from '../../../services/confirmation-dialog-service';
import { HttpService } from '../../../services/http-service';
import { ToastService } from '../../../services/toast-service';
import { TranslationService } from '../../../services/translation-service';
import { AdminEditDialogComponent } from '../admin-edit-dialog/admin-edit-dialog.component';
import { RequestInfoDialogComponent } from '../request-info-dialog/request-info-dialog.component';

@Component({
  selector: 'app-translation-request',
  templateUrl: './translation-request.component.html',
  styleUrls: ['./translation-request.component.scss']
})
export class TranslationRequestComponent implements OnInit {
  @Input() translationRequest: TranslationRequest;
  @Input() viewType: FilterType; // describes if the request is currently showed in ALL or OWN requestList 
  @Output() refreshRequestList = new EventEmitter<FilterType>();
  @Output() editRequest = new EventEmitter<TranslationRequest>();

  //enums
  VIEW_TYPE = FilterType;
  USER_ROLE = Role;
  REQUEST_STATUS = RequestStatus;

  constructor(
    public translationService: TranslationService,
    private toastService: ToastService,
    public httpService: HttpService,
    private dialog: MatDialog,
    private confirmationDialogService: ConfirmationDialogService
  ) { }

  ngOnInit(): void {
  }

  async assignRequest(requestNr: number) {
    const confirmed = await this.confirmationDialogService.openConfirmationDialog({ message: 'CONFIRMATION_MESSAGE_TAKEOVER_REQUEST' });
    // call a confirmation before Assigning Request
    if (confirmed) {
      const assignRequestResp = await this.httpService.get(`assignRequest`, { requestNr: requestNr }) as API_RESPONSE;
      if (assignRequestResp.success && assignRequestResp.data) {
        // this.toastService.showSuccessSnack('Request assigned successfully', 'OK');
        this.toastService.showInfoSnack('TAKE_OVER_REQUEST_INSTRUCTIONS', 'UNDERSTOOD', 999999);
        this.refreshRequestList.emit(FilterType.MATCHING)
      } else {
        this.toastService.showErrorSnack(assignRequestResp.message, 'OK');
      }
    }
  }

  showInfoDialog(requestData: any) {
    const infoDialogRef = this.dialog.open(RequestInfoDialogComponent, {
      data: requestData,
      // width: 'fit-content',
      // height: 'fit-content',
      minWidth: '25rem',
      panelClass: 'request-info-dialog',
    })
    infoDialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.refreshRequestList.emit(result)
      }
    });
  }

  openAdminEditDialog(requestData: any) {
    const adminDialogRef = this.dialog.open(AdminEditDialogComponent, {
      data: requestData,
      width: 'fit-content',
      height: 'fit-content',
      minWidth: '25rem',
    })
  }

  async changeRequestStatus(requestNr: number, status: RequestStatus) {
    let confirmed = true;
    let refreshFilter = FilterType.MATCHING;
    switch (status) {
      // case RequestStatus.DELETED:
      //   // call a confirmation before Deleting Request
      //   confirmed = await this.confirmationDialogService.openConfirmationDialog({ message: 'CONFIRMATION_MESSAGE_DELETE_REQUEST' });
      //   refreshFilter = FilterType.OWN;
      //   break;
      case RequestStatus.DONE:
        // call a confirmation before finishing Request
        confirmed = await this.confirmationDialogService.openConfirmationDialog({ message: 'CONFIRMATION_MESSAGE_MARK_AS_DONE' });
        refreshFilter = FilterType.OWN;
        break;
      case RequestStatus.CLOSED:
        // call a confirmation before finishing Request
        confirmed = await this.confirmationDialogService.openConfirmationDialog({ message: 'CONFIRMATION_MESSAGE_MARK_AS_DONE' });
        refreshFilter = FilterType.OWN;
        break;
    }
    if (confirmed) {
      const changeRequestStatusResp = await this.httpService.get('changeRequestStatus', { requestNr: requestNr, status: status }) as API_RESPONSE;
      if (changeRequestStatusResp.success && changeRequestStatusResp.data) {
        this.toastService.showSuccessSnack(changeRequestStatusResp.message, 'OK', 5000);
        // this.toastService.showSuccessSnack('Request changed status successfully', 'OK');
        this.refreshRequestList.emit(refreshFilter)
      } else {
        this.toastService.showErrorSnack(changeRequestStatusResp.message, 'OK');
      }
    }

  }

  async deleteRequest(requestNr: number) {
    const confirmed = await this.confirmationDialogService.openConfirmationDialog({ message: 'CONFIRMATION_MESSAGE_DELETE_REQUEST' });
    if (confirmed) {
      const changeRequestStatusResp = await this.httpService.get('deleteRequest', { requestNr: requestNr }) as API_RESPONSE;
      if (changeRequestStatusResp.success) {
        this.toastService.showSuccessSnack(changeRequestStatusResp.message, 'OK', 5000);
        // this.toastService.showSuccessSnack('Request changed status successfully', 'OK');
        this.refreshRequestList.emit(FilterType.OWN)
      }else {
        this.toastService.showErrorSnack(changeRequestStatusResp.message, 'OK');
      }
    }
  }

}
