import { Injectable } from "@angular/core";
import { BehaviorSubject, Subject } from "rxjs";

@Injectable({ providedIn: 'root' })
export class NavigationService {
  showHeaderNavigation = new BehaviorSubject<boolean>(true);
  showFooterNavigation = new BehaviorSubject<boolean>(true);
  refreshRequestList = new Subject<boolean>();
  constructor() {
  }
}