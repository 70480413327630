<section class="menu-horizontal-end bg-secondary" *ngIf="navService.showHeaderNavigation | async">
  <a href="https://translatorsagainstwar.com/#/translation-requests">
    <img class="nav-logo" *ngIf="!globalsService.isMobileView" src="../../../assets/images/translatorsagainstwar_logo.png">
    <img class="nav-logo-small" *ngIf="globalsService.isMobileView" src="../../../assets/images/translatorsagainstwar_logo_small.png">
  </a>

  <mat-form-field appearance="outline" class="lang-select borderless-select">
    <!-- <mat-label>LANGUAGE</mat-label> -->
    <mat-select [ngModel]="translationService.userLanguage.friendlyName"
      (selectionChange)="changeLanguage($event.value)">
      <mat-option *ngFor="let language of translationService.languagesList" [value]="language.friendlyName">
        {{ language.friendlyName | Translate: (translationService.$currentTranslation | async)}}
      </mat-option>
    </mat-select>
    <!-- <mat-icon matPrefix inline="true" class="lang-icon">translate</mat-icon> -->
  </mat-form-field>
  
  <button mat-icon-button [disabled]="!httpService.sessionid" style="margin: auto" (click)="drawerToggle.emit()">
    <mat-icon inline="true" class="logout-icon">person</mat-icon>
  </button>

  <button mat-icon-button style="margin: auto">
    <a *ngIf="httpService.role === 'TRANSLATOR' || httpService.role === 'ADMIN'"
      [href]="'../../../assets/files/TUTORIAL_TRANSLATOR_DEU.pdf'" target="_blank">
      <mat-icon inline="true" color="accent" class="logout-icon">help</mat-icon>
    </a>
    <a *ngIf="httpService.role === 'REQUESTOR'" target="_blank"
      [href]="'../../../assets/files/TUTORIAL_REQUESTOR_'+translationService.userLanguage.shortName+'.pdf'">
      <mat-icon inline="true" color="accent" class="logout-icon">help</mat-icon>
    </a>
  </button>

  <!-- <button mat-icon-button [disabled]="!httpService.sessionid" style="margin: 1rem 1rem 0.5rem 0rem !important;" -->
  <button mat-icon-button style="margin: auto !important;"
    (click)="logout()">
    <mat-icon matPrefix inline="true" class="logout-icon">logout</mat-icon>
  </button>
</section>