<div class="modal-header">
  <h3 class="modal-title subtitle">{{title | Translate: (translationService.$currentTranslation | async) }}</h3>
</div>
<div class="modal-body" >
  {{ message | Translate: (translationService.$currentTranslation | async) }}
</div>
<div class="modal-footer">
  <button mat-button color="primary" (click)="accept()">
    {{ btnOkText | Translate: (translationService.$currentTranslation | async) }}
  </button>
  <button mat-button color="warn" (click)="decline()">
    {{ btnCancelText | Translate: (translationService.$currentTranslation | async)}}
  </button>
</div>
