import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { API_RESPONSE } from '../../interfaces';
import { HttpService } from '../../services/http-service';
import { TranslationService } from '../../services/translation-service';

@Component({
  selector: 'app-delete-request',
  templateUrl: './delete-request.component.html',
  styleUrls: ['./delete-request.component.scss']
})
/**
 * This component is used to delete a request, normally user would come here from an email link
 */
export class DeleteRequestComponent implements OnInit {
  deleteResponse: API_RESPONSE;
  constructor(
    private httpService: HttpService,
    private route: ActivatedRoute,
    public translationService: TranslationService,) { }

  async ngOnInit(): Promise<void> {
    // check query params for requestNr & sessionid 
    const requestNr =  this.route.snapshot?.queryParams?.requestNr;
    const sessionid = this.route.snapshot?.queryParams?.sessionid;
    if (requestNr && sessionid) {
      this.deleteResponse = await this.httpService.get('deleteRequest', { requestNr, sessionid });
    }
  }

}
