import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { Meta, Title } from '@angular/platform-browser';
import { Router, Scroll } from '@angular/router';
import { debounceTime } from 'rxjs/operators';
import { Language } from './interfaces';
import { HttpService } from './services/http-service';
import { NavigationService } from './services/navigation.service';
import { TranslationService } from './services/translation-service';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent implements OnInit {
  // title = 'LABEL_NEW_ORDER';
  @ViewChild('drawer', { static: true }) drawer: MatSidenav;
  navigationElements = [
    // { path: 'create-order', label: 'LABEL_NEW_ORDER' },
    // { path: 'order-list', label: 'LABEL_ORDER_ADMINISTRATION' },
    // { path: 'menu-administration', label: 'LABEL_MENU_ADMINISTRATION' },
  ];
  constructor(
    private router: Router,
    public httpService: HttpService,
    public translationService: TranslationService,
    public navService: NavigationService,    
    private meta: Meta, // used for adding meta Tags
    private title: Title,
  ) {
    ////////// SEO //////////
    this.meta.addTag({ name: 'description', content: 'Translators4Ukraine --> TranslatorsAgainstWar - Volunteer Translations for Refugees' });  
    this.meta.addTags([
      { name: 'description', content: 'Translators4Ukraine --> TranslatorsAgainstWar - Volunteer Translations for Refugees find free translation help in germany' },
      { name: 'author', content: 'Michael Rabinovych, Elisabeth Jäger' },
      { name: 'keywords', content: 'Translators4Ukraine, TranslatorsAgainstWar, Volunteer Translations for Refugees, translation, help, german, ukrain, russian, ukrainian, translators, voluntery, перевод, помощь, немецкий, украинский, übersetzung, ukrainisch, hilfe, russisch' },
      { name: 'robots', content: 'index, follow' },
      { name: 'viewport', content: 'width=device-width, initial-scale=1' },
      { charset: 'UTF-8' }
    ]); 
    this.title.setTitle('TranslatorsAgainstWar');

  }

  ngOnInit() {
    this.router.events.pipe(debounceTime(100)).subscribe((event: Scroll) => {
      const selectedRoute = this.navigationElements.find(elem => elem.path === event.routerEvent.urlAfterRedirects.replace('/', ''))
      // this.title = 'header' // selectedRoute.label;
      // this.drawer.close();
    });
  }

  async changeLanguage(language: Language) {
    this.translationService.changeLanguage(language);
    if(this.httpService.isAuthenticated()){
      await this.httpService.post('changeUserLanguage', { language: language });
    }
  }

  logout() {
    localStorage.removeItem('sessionid');
    this.httpService.sessionid = '';
    this.router.navigate(['login']);
  }

  openPersonalSettings(){
    
  }
}
