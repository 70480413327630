import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { API_RESPONSE, FilterType, RequestStatus, TranslationRequest } from '../../../interfaces';
import { HttpService } from '../../../services/http-service';
import { TranslationService } from '../../../services/translation-service';
import { ToastService } from '../../../services/toast-service';
import { ConfirmationDialogService } from '../../../services/confirmation-dialog-service';

@Component({
  selector: 'app-request-info-dialog',
  templateUrl: './request-info-dialog.component.html',
  styleUrls: ['./request-info-dialog.component.scss'],
})
export class RequestInfoDialogComponent implements OnInit {
  showContacts = false;
  constructor(
    public dialogRef: MatDialogRef<RequestInfoDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public requestData: TranslationRequest,
    public translationService: TranslationService,
    public httpService: HttpService,
    private toastService: ToastService,
    private confirmationDialogService: ConfirmationDialogService
  ) {}

  async ngOnInit(): Promise<void> {
    await this.getRequestorContactsByRequestNr(this.requestData.Nr);
    console.log('%cLOGGER', 'background: green', ': RequestInfoDialogComponent -> requestData', this.requestData);
  }

  async getRequestorContactsByRequestNr(requestNr: number) {
    const getTranslationRequestsResp = (await this.httpService.get(`getRequestorContactsByRequestNr`, {
      requestNr: requestNr,
    })) as API_RESPONSE;
    if (getTranslationRequestsResp.success && getTranslationRequestsResp.data) {
      this.requestData.requestor = getTranslationRequestsResp.data;
      this.showContacts = true;
    }
  }

  async assignRequest(requestNr: number) {
    const getTranslationRequestsResp = (await this.httpService.get(`assignRequest`, {
      requestNr: requestNr,
    })) as API_RESPONSE;
    if (getTranslationRequestsResp.success && getTranslationRequestsResp.data) {
      this.requestData = { ...this.requestData, ...getTranslationRequestsResp.data };
      this.showContacts = true;
    }
  }

  async cancelRequest(requestNr: number) {
    let confirmed = true;
    confirmed = await this.confirmationDialogService.openConfirmationDialog({
      message: 'CONFIRMATION_MESSAGE_ABANDON',
    });

    if (confirmed) {
      const changeRequestStatusResp = (await this.httpService.get('changeRequestStatus', {
        requestNr: requestNr,
        status: RequestStatus.CANCELED,
      })) as API_RESPONSE;
      if (changeRequestStatusResp.success && changeRequestStatusResp.data) {
        this.toastService.showSuccessSnack(changeRequestStatusResp.message, 'OK', 5000);
        this.closeDialog(FilterType.OWN);
      } else {
        this.toastService.showErrorSnack(changeRequestStatusResp.message, 'OK');
      }
    }
  }

  closeDialog(refreshFilter?: FilterType) {
    if (refreshFilter) {
      this.dialogRef.close(refreshFilter);
    }else{
    this.dialogRef.close();
    }
  }
}
