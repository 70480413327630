import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'filter',
    pure: true
})

export class FilterPipe implements PipeTransform {
    transform(items: any[], valueToMatch:string, prop?: string): any {
        const filteredItems = items.filter(item => item === valueToMatch || item[prop] === valueToMatch);
        console.log('%cLOGGER', 'background: cyan',' filteredItems', filteredItems);
        return filteredItems;
    }
}