<mat-card class="card">
  <div class="tags">
    <label class="tag text-sm" [ngClass]="translationRequest.requestType === 'INTERPRET' ? 'orange-tag': 'green-tag'">
      {{translationRequest.requestType | Translate: (translationService.$currentTranslation | async)}}
    </label>
    <label class="tag text-sm" [ngClass]="translationRequest.languageFrom === 'RUSSIAN'? 'blue-tag': 'yellow-tag'">
      {{translationRequest.languageFrom | Translate: (translationService.$currentTranslation | async)}}
      - {{translationRequest.languageTo | Translate: (translationService.$currentTranslation | async)}}
    </label>
    <mat-icon *ngIf="translationRequest.status === REQUEST_STATUS.WAITING" class="status-icon yellow-tag" [matTooltip]="'WAITING' | Translate: (translationService.$currentTranslation | async)">
      pending
    </mat-icon>
    <mat-icon *ngIf="translationRequest.status === REQUEST_STATUS.IN_PROGRESS" class="status-icon orange-tag" [matTooltip]="'IN_PROGRESS' | Translate: (translationService.$currentTranslation | async)">
      hourglass_top
    </mat-icon>
    <mat-icon *ngIf="translationRequest.status === REQUEST_STATUS.DONE" class="status-icon blue-tag" [matTooltip]="'DONE' | Translate: (translationService.$currentTranslation | async)">
      check
    </mat-icon>
    <mat-icon *ngIf="translationRequest.status === REQUEST_STATUS.CLOSED" class="status-icon green-tag " [matTooltip]="'CLOSED' | Translate: (translationService.$currentTranslation | async)">
      done_all
    </mat-icon>
    <mat-icon *ngIf="translationRequest.status === REQUEST_STATUS.DELETED" class="status-icon red-tag" [matTooltip]="'DELETED' | Translate: (translationService.$currentTranslation | async)">
      delete_forever
    </mat-icon>
  </div>
  <span class="color-primary hide-long-title">{{translationRequest.textType || translationRequest.appointmentTheme}}</span>
  <mat-card-content class="card-description">
    <p class="card-description-text" style="margin-top:0.5rem;">{{translationRequest.description}}</p>
    <mat-divider></mat-divider>
    <section class="icon-section">
      <section class="icon-info">
      <div class="icon-info-item" [matTooltip]="'REQUEST_NR'| Translate: (translationService.$currentTranslation | async)">
        <mat-icon class="icon-width">tag</mat-icon>
        <span>{{translationRequest.Nr}}</span>
      </div>
      <div class="icon-info-item"  [matTooltip]="'CREATION_DATE'| Translate: (translationService.$currentTranslation | async)">
        <mat-icon class="icon-width">calendar_month</mat-icon>
        <span>{{translationRequest.createdAt | date: 'dd.MM.yyyy'}}</span>
      </div>
      <div class="icon-info-item" *ngIf="translationRequest.distance" [matTooltip]="'LESS_THEN ' +  translationRequest.distance + ' KM'| Translate: (translationService.$currentTranslation | async)">
        <mat-icon class="icon-width">explore</mat-icon>
        <span>{{ '\< ' +  translationRequest.distance + ' KM'  | Translate: (translationService.$currentTranslation | async)}}</span>
      </div>
    </section>

    <section class="icon-info">
      <div *ngIf="translationRequest.textAmountOfPages" class="icon-info-item" [matTooltip]="'AMOUNT_OF_PAGES'| Translate: (translationService.$currentTranslation | async)">
        <mat-icon class="icon-width">auto_stories</mat-icon>
        <span>{{translationRequest.textAmountOfPages}}</span>
      </div>
      <div *ngIf="translationRequest.handwritten" class="icon-info-item"  [matTooltip]="'HANDWRITTEN'| Translate: (translationService.$currentTranslation | async)">
        <mat-icon class="icon-width">gesture</mat-icon>
        <span>{{"HANDWRITTEN_SHORT" | Translate: (translationService.$currentTranslation | async)}}</span>
      </div>
      <div *ngIf="translationRequest.handwritten" class="icon-info-item"  [matTooltip]="'MEDICAL_TRANSLATION'| Translate: (translationService.$currentTranslation | async)">
        <mat-icon class="icon-width">medical_services</mat-icon>
        <span>{{"MEDICAL_SHORT" | Translate: (translationService.$currentTranslation | async)}}</span>
      </div>

      <div *ngIf="translationRequest.appointmentAddress" class="icon-info-item"  [matTooltip]="'APPOINTMENT_ADDRESS : ' + translationRequest.appointmentAddress | Translate: (translationService.$currentTranslation | async)">
        <mat-icon class="icon-width">location_on</mat-icon>
        <span class="hide-long-text">{{translationRequest.appointmentAddress}}</span>
      </div>
      <div *ngIf="translationRequest.appointmentDateTime && translationRequest.flexibleDate" class="icon-info-item"  [matTooltip]="'APPOINTMENT_DATE_TIME : ' + translationRequest.appointmentDateTime | Translate: (translationService.$currentTranslation | async)">
        <mat-icon class="icon-width">event</mat-icon>
        <span class="hide-long-text">{{translationRequest.appointmentDateTime | date: "dd.MM hh:mm"}}</span>
      </div>
      <div *ngIf="translationRequest.flexibleDate" class="icon-info-item"  [matTooltip]="'FLEXIBLE_DATE'| Translate: (translationService.$currentTranslation | async)">
        <mat-icon class="icon-width">event</mat-icon>
        <span>{{'FLEXIBLE_DATE' | Translate: (translationService.$currentTranslation | async)}}</span>
      </div>
      <div *ngIf="translationRequest.appointmentDuration" class="icon-info-item"  [matTooltip]="'APPOINTMENT_DURATION'| Translate: (translationService.$currentTranslation | async)">
        <mat-icon class="icon-width">hourglass_top</mat-icon>
        <span>{{translationRequest.appointmentDuration + (' HOURS' | Translate: (translationService.$currentTranslation | async))}}</span>
      </div>
      <div *ngIf="translationRequest.phoneInterpretation" class="icon-info-item"  [matTooltip]="'PHONE_INTERPRETATION'| Translate: (translationService.$currentTranslation | async)">
        <mat-icon class="icon-width">headset_mic</mat-icon>
        <span>{{'PHONE_INTERPRETATION_SHORT' | Translate: (translationService.$currentTranslation | async)}}</span>
      </div>      
      <!-- <div [style.visibility]="translationRequest.isOfficial ? 'visible' : 'hidden'" class="icon-info-item">
        <mat-icon class="icon-width">approval</mat-icon>
        <span>{{'AUTHORIZATION_REQUIRED'| Translate: (translationService.$currentTranslation | async)}}</span>
      </div> -->
      </section>
    </section>
  </mat-card-content>
  <mat-card-actions align="end">
    <button mat-button 
    *ngIf="translationRequest.status !== REQUEST_STATUS.WAITING || (httpService.role === USER_ROLE.TRANSLATOR || httpService.role === USER_ROLE.ADMIN)"
    (click)="showInfoDialog(translationRequest)">
      {{'MORE_INFO'| Translate: (translationService.$currentTranslation | async)}}
    </button>
    <button mat-button
    *ngIf="httpService.role === USER_ROLE.ADMIN || (httpService.role === USER_ROLE.REQUESTOR && translationRequest.status === REQUEST_STATUS.WAITING)"
     (click)="editRequest.emit(translationRequest)">
      {{'EDIT_REQUEST'| Translate: (translationService.$currentTranslation | async)}}
    </button>
    <button mat-button *ngIf="viewType === VIEW_TYPE.MATCHING && httpService.role === USER_ROLE.TRANSLATOR"
      (click)="assignRequest(translationRequest.Nr)">
      {{'ACCEPT'| Translate: (translationService.$currentTranslation | async)}}
    </button>
    <button mat-button *ngIf="viewType === VIEW_TYPE.OWN && httpService.role === USER_ROLE.TRANSLATOR && translationRequest.status === REQUEST_STATUS.IN_PROGRESS"
      (click)="changeRequestStatus(translationRequest.Nr, REQUEST_STATUS.DONE)">
      {{'MARK_AS_DONE'| Translate: (translationService.$currentTranslation | async)}}
    </button>
    <button mat-button
      *ngIf="httpService.role === USER_ROLE.ADMIN || (viewType === VIEW_TYPE.OWN && httpService.role === USER_ROLE.REQUESTOR && translationRequest.status === REQUEST_STATUS.WAITING)"
      (click)="deleteRequest(translationRequest.Nr)">
      {{'DELETE'| Translate: (translationService.$currentTranslation | async)}}
    </button>
    <!-- <button mat-button
      *ngIf="viewType === VIEW_TYPE.OWN && httpService.role === USER_ROLE.TRANSLATOR && translationRequest.status === REQUEST_STATUS.IN_PROGRESS"
      (click)="changeRequestStatus(translationRequest.Nr,REQUEST_STATUS.CANCELED)">
      {{'CANCEL'| Translate: (translationService.$currentTranslation | async)}}
    </button> -->
    <button mat-button *ngIf="viewType === VIEW_TYPE.OWN  && httpService.role === USER_ROLE.REQUESTOR && translationRequest.status === REQUEST_STATUS.DONE"
      (click)="changeRequestStatus(translationRequest.Nr, REQUEST_STATUS.CLOSED)">
      {{'COMPLETE'| Translate: (translationService.$currentTranslation | async)}}
    </button>
  </mat-card-actions>
</mat-card>