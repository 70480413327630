import { Injectable } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { first } from "rxjs/operators";
import { ConfirmationDialogData } from "../interfaces";
import { ConfirmationDialogComponent } from "../shared/confirmation-dialog/confirmation-dialog.component";
import { TranslationService } from "./translation-service";

@Injectable({ providedIn: 'root' })
export class ConfirmationDialogService {

  constructor(    
    private dialog: MatDialog
  ) {}

  async openConfirmationDialog(confDialogData?:ConfirmationDialogData){
    const createRequestDialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: confDialogData,
      width: 'fit-content',
      height: 'fit-content',
      minWidth: '25rem',
      panelClass: 'request-info-dialog',
    });
    return await createRequestDialogRef.afterClosed().pipe(first()).toPromise();
  }

  }