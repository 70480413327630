import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatDatepickerModule } from '@matheo/datepicker'; // override Material datepicker modules with Matheo modules 
import { MatNativeDateModule } from '@matheo/datepicker/core'; // override Material datepicker modules with Matheo modules 
import { NgChartsModule } from 'ng2-charts';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MaterialModule } from './material.module';
import { DeleteRequestComponent } from './modules/delete-request/delete-request.component';
import { HeaderMenuComponent } from './modules/header-menu/header-menu.component';
import { ForgotPasswordComponent } from './modules/login/forgot-password/forgot-password.component';
import { LoginComponent } from './modules/login/login.component';
import { ProfileComponent } from './modules/profile/profile.component';
import { SignUpComponent } from './modules/sign-up/sign-up.component';
import { CreateTranslationRequestComponent } from './modules/translation-request-list/create-translation-request/create-translation-request.component';
import { RequestInfoDialogComponent } from './modules/translation-request-list/request-info-dialog/request-info-dialog.component';
import { TranslationRequestListComponent } from './modules/translation-request-list/translation-request-list.component';
import { TranslationRequestComponent } from './modules/translation-request-list/translation-request/translation-request.component';
import { FilterPipe } from './pipes/filter-pipe';
import { Translate } from './pipes/translation-pipe';
import { ConfirmationDialogComponent } from './shared/confirmation-dialog/confirmation-dialog.component';

@NgModule({
  declarations: [
    AppComponent,
    SignUpComponent,
    LoginComponent,
    ForgotPasswordComponent,
    TranslationRequestListComponent,
    RequestInfoDialogComponent,
    TranslationRequestComponent,
    CreateTranslationRequestComponent,
    ProfileComponent,
    HeaderMenuComponent,
    ConfirmationDialogComponent,
    DeleteRequestComponent,
    Translate,
    FilterPipe
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MaterialModule,
    HttpClientModule,
    MatDatepickerModule,
    MatNativeDateModule,
    NgChartsModule,
  ],
  providers: [Translate, FilterPipe],
  bootstrap: [AppComponent]
})
export class AppModule { }
